import GridViewIcon from '@mui/icons-material/GridView';
import TableChartIcon from '@mui/icons-material/TableChart';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { KeyResultCardListVariants } from '../KeyResultCardList/KeyResultCardList';

interface KeyResultFilterViewModeProps {
  value: KeyResultCardListVariants;
  onChange: (value: KeyResultCardListVariants) => void;
}

function KeyResultFilterViewMode({ value, onChange }: KeyResultFilterViewModeProps) {
  return (
    <ToggleButtonGroup
      exclusive
      data-testid="view-mode"
      size="small"
      value={value}
      onChange={(_, mode) => mode && onChange(mode)}
      sx={{
        color: 'action.disabled',
        '& .MuiToggleButtonGroup-grouped': {
          border: 0,
          '&:not(:first-of-type)': {
            borderRadius: ({ spacing }) => spacing(1),
          },
          '&:first-of-type': {
            borderRadius: ({ spacing }) => spacing(1),
          },
          '&.Mui-selected': { bgcolor: 'inherit' },
        },
      }}
    >
      <ToggleButton value="list-details" sx={{ color: 'inherit' }}>
        <GridViewIcon />
      </ToggleButton>

      <ToggleButton value="table" sx={{ color: 'inherit' }}>
        <TableChartIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default KeyResultFilterViewMode;
