import { Box, Divider, Stack, Tooltip, styled } from '@mui/material';

import Definition from '../../Definition/Definition';

interface KeyResultCardAgreementProps {
  agree: string[];
  disagree: string[];
  missing: string[];
}

function KeyResultCardAgreement({ agree, disagree, missing }: KeyResultCardAgreementProps) {
  const total = agree.length + disagree.length + missing.length;

  return (
    <Tooltip
      data-testid="key-result-card-agreement"
      title={
        <Stack data-testid="key-result-card-agreement-tip" whiteSpace="pre-line">
          <TipTitle>Agree: ({agree.length})</TipTitle>
          {agree.join('\n')}
          <TipDivider />
          <TipTitle>Disagree: ({disagree.length})</TipTitle>
          {disagree.join('\n')}
          <TipDivider />
          <TipTitle>No action: ({missing.length})</TipTitle>
          {missing.join('\n')}
        </Stack>
      }
    >
      <Box>
        <Definition direction="row" label="Agreements" value={`${agree.length}/${total}`} />
      </Box>
    </Tooltip>
  );
}

const TipTitle = styled('span')(({ theme }) => ({
  color: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '1px',
}));

const TipDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  borderColor: theme.palette.grey[500],
}));

export default KeyResultCardAgreement;
