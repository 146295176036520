import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { userContext } from '../context';

interface ChangePasswordResult {
  changePassword: {
    id: string;
    name: string;
    email: string;
    roles: string[];
    forcePasswordChangeFlag: boolean;
    employee: {
      id: string;
      preferredName: string;
      profilePicture: string;
    };
  };
}

export function useChangePassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setUser: setUserContext } = useContext(userContext);

  const result = useMutation<ChangePasswordResult>(changePasswordMutation, {
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: (data) => {
      enqueueSnackbar('Password changed successfully', { variant: 'success' });
      setUserContext(data.changePassword);
      navigate(0);
    },
  });
  return result;
}

export const changePasswordMutation = gql`
  mutation ($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
      email
      name
      roles
      force_password_change_flag
      employee {
        id
        preferredName
        profilePicture
      }
    }
  }
`;
