import { Tooltip, Typography } from '@mui/material';

export interface KeyResultCardObjectiveProps {
  name: string;
  description: string;
}

function KeyResultCardObjective(props: KeyResultCardObjectiveProps) {
  return (
    <Tooltip title={props.description} placement="right">
      <Typography variant="caption" color="primary" sx={{ textDecorationLine: 'underline' }}>
        {props.name}
      </Typography>
    </Tooltip>
  );
}

export default KeyResultCardObjective;
