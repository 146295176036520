import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { Route } from '../../utils';
import Filter, { FilterFieldWrapper } from '../Filter/Filter';
import SearchField from '../SearchField/SearchField';

export interface UserFilterProps {
  onNameSubmit: (name: string) => void;
  initialValue?: string | null;
}

function UserFilter(props: UserFilterProps) {
  return (
    <Filter>
      <FilterFieldWrapper>
        <SearchField
          variant="standard"
          placeholder="Filter by user's name"
          onSubmit={props.onNameSubmit}
          initialValue={props.initialValue || ''}
        />
      </FilterFieldWrapper>

      <FilterFieldWrapper flexGrow={1}>
        <Button variant="contained" size="small" component={Link} to={Route.CREATE_USER}>
          Create user
        </Button>
      </FilterFieldWrapper>
    </Filter>
  );
}

export default UserFilter;
