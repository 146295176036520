import { Box, Tooltip } from '@mui/material';

import { FocusArea } from '../../../utils';
import getIconAndCountryName from '../../../utils/getFocusAreaIconAndCountryName/getIconAndCountryName';

export interface KeyResultCardFocusAreaProps {
  focusArea: FocusArea;
}

function KeyResultCardFocusArea({ focusArea }: KeyResultCardFocusAreaProps) {
  const countryInfo = getIconAndCountryName(focusArea);

  return (
    <Tooltip title={countryInfo?.countryName} arrow>
      <Box
        data-testid="focus-area"
        component="img"
        src={countryInfo?.FlagIcon}
        alt={`${focusArea}-flag`}
        sx={{
          width: '28px',
          height: '20px',
          marginTop: '-4px',
          verticalAlign: 'middle',
        }}
      />
    </Tooltip>
  );
}

export default KeyResultCardFocusArea;
