import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useContext, useMemo } from 'react';

import { KeyResultUpdateFormatted, formatKeyResultUpdates } from './mappers/keyResultUpdate.mapper';
import { userContext } from '../context';
import { KeyResultUpdate, User } from '../utils';

interface GetKeyResultUpdates {
  getKeyResultUpdatesForKr: Array<KeyResultUpdate & { user: User }>;
}

export function useGetKeyResultUpdates(id: string) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(userContext);

  const { data, ...result } = useQuery<GetKeyResultUpdates>(getKeyResultUpdatesQuery, {
    variables: { id },
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const mappedData = useMemo(
    function () {
      if (!data || !data.getKeyResultUpdatesForKr) {
        return [];
      }

      return formatKeyResultUpdates(
        user!,
        data.getKeyResultUpdatesForKr
      ) as KeyResultUpdateFormatted[];
    },
    [data, user]
  );

  return { data: { getKeyResultUpdatesForKr: mappedData }, ...result };
}

export const getKeyResultUpdatesQuery = gql`
  query GetKeyResultUpdates($id: String!) {
    getKeyResultUpdatesForKr(kr_id: $id) {
      id
      type
      payload
      createdAt
      updatedAt
      user {
        id
        name
        is_active
        employee {
          preferredName
        }
      }
      directManager {
        name
        preferredName
      }
    }
  }
`;
