import { Objective } from '../../utils';
import { FormattedResponse } from '../service.utils';

export type ObjectiveFormattedResponse<BObj> = FormattedResponse<
  ObjectiveFormattedToMapper,
  BObj,
  ObjectiveMappedFields
>;

export interface ObjectiveFormattedToMapper extends ObjectiveCommon {}
export interface ObjectiveFormatted extends ObjectiveCommon {}
export type ObjectiveCommon = Objective;
export type ObjectiveMappedFields = {};

export function formatObjective(obj: Partial<Objective> = {}) {
  return {
    ...formatId(obj.id),
    ...formatName(obj.name),
    ...formatDescription(obj.description),
    ...formatDeadline(obj.deadline),
  } as Partial<ObjectiveFormatted>;
}

function formatId(id?: string) {
  return id ? { id } : null;
}

function formatName(name?: string) {
  return name ? { name } : null;
}

function formatDescription(description?: string) {
  return description ? { description } : null;
}

function formatDeadline(deadline?: string) {
  return deadline ? { deadline } : null;
}
