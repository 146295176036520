import { lazy } from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';

import { HasRole, PageViewMeasurement, PrivatePage } from '../components';
import DetailEmployee from '../pages/DetailEmployee/DetailEmployee';
import EditKeyResult from '../pages/EditKeyResult/EditKeyResult';
import { Route } from '../utils';

const CreateUser = lazy(() => import('../pages/CreateUser/CreateUser'));
const Generic = lazy(() => import('../pages/Errors/Generic'));
const PageLayout = lazy(() => import('../components/PageLayout/PageLayout'));
const Login = lazy(() => import('../pages/Login/Login'));
const Logout = lazy(() => import('../pages/Logout/Logout'));
const KeyResults = lazy(() => import('../pages/KeyResults/KeyResults'));
const KeyResultsDetails = lazy(() => import('../pages/KeyResultsDetails/KeyResultsDetails'));
const EditUser = lazy(() => import('../pages/EditUser/EditUser'));
const ListUser = lazy(() => import('../pages/ListUser/ListUser'));
const ChangePassword = lazy(() => import('../pages/ChangePassword/ChangePassword'));
const ChangePasswordAsAdmin = lazy(
  () => import('../pages/ChangePasswordAsAdmin/ChangePasswordAsAdmin')
);
const ListObjectives = lazy(() => import('../pages/ListObjectives/ListObjectives'));
const ObjectiveDetails = lazy(() => import('../pages/ObjectiveDetails/ObjectiveDetails'));
const CreateObjective = lazy(() => import('../pages/CreateObjective/CreateObjective'));
const CreateKeyResult = lazy(() => import('../pages/CreateKeyResult/CreateKeyResult'));
const EditObjective = lazy(() => import('../pages/EditObjective/EditObjective'));
const CreateAppraisal = lazy(() => import('../pages/CreateAppraisal/CreateAppraisal'));
const AppraisalProvider = lazy(() => import('../pages/CreateAppraisal/CreateAppraisal.context'));
const ListEmployees = lazy(() => import('../pages/ListEmployees/ListEmployees'));
const OrgChart = lazy(() => import('../pages/OrgChart/OrgChart'));
const ManageAppraisals = lazy(() => import('../pages/ManageAppraisals/ManageAppraisals'));

export const router = createBrowserRouter([
  {
    path: Route.HOME,
    element: <PageViewMeasurement />,
    errorElement: <Generic />,
    children: [
      {
        path: Route.LOGIN,
        element: <Login />,
        errorElement: <Generic />,
      },
      {
        path: Route.LOGOUT,
        element: <Logout />,
        errorElement: <Generic />,
      },
      {
        path: Route.HOME,
        element: <PrivatePage />,
        errorElement: <Generic />,
        children: [
          {
            path: Route.FORCE_CHANGE_PASSWORD,
            element: <ChangePassword />,
          },
          {
            path: Route.HOME,
            element: <PageLayout />,
            children: [
              {
                path: Route.KRS,
                element: <KeyResults />,
              },
              {
                path: Route.KRS_DETAILS,
                element: <KeyResultsDetails />,
              },
              {
                path: Route.KRS_DETAILS,
                element: <HasRole roles={['admin']} />,
                children: [
                  {
                    path: Route.EDIT_KR,
                    element: <EditKeyResult />,
                  },
                ],
              },
              {
                path: Route.CREATE_KR,
                element: <HasRole roles={['admin']} />,
                children: [
                  {
                    path: Route.CREATE_KR,
                    element: <CreateKeyResult />,
                  },
                ],
              },
              {
                path: Route.LIST_USERS,
                element: <HasRole roles={['admin']} />,
                children: [
                  {
                    path: Route.LIST_USERS,
                    element: <ListUser />,
                  },
                  {
                    path: Route.CREATE_USER,
                    element: <CreateUser />,
                  },
                  {
                    path: Route.EDIT_USER,
                    element: <EditUser />,
                  },
                  {
                    path: Route.CHANGE_PASSWORD_AS_ADMIN,
                    element: <ChangePasswordAsAdmin />,
                  },
                ],
              },
              {
                path: Route.LIST_EMPLOYEES,
                element: <ListEmployees />,
              },
              {
                path: Route.LIST_EMPLOYEES,
                element: <HasRole roles={['admin']} />,
                children: [
                  {
                    path: Route.DETAIL_EMPLOYEE,
                    element: <DetailEmployee />,
                  },
                ],
              },
              {
                path: Route.LIST_OBJECTIVES,
                element: <ListObjectives />,
              },
              {
                path: Route.LIST_OBJECTIVES,
                element: <HasRole roles={['admin']} />,
                children: [
                  {
                    path: Route.CREATE_OBJECTIVE,
                    element: <CreateObjective />,
                  },
                  {
                    path: Route.EDIT_OBJECTIVE,
                    element: <EditObjective />,
                  },
                  {
                    path: Route.OBJECTIVES_DETAILS,
                    element: <ObjectiveDetails />,
                  },
                ],
              },
              {
                path: Route.CHANGE_PASSWORD,
                element: <ChangePassword hideBreadcrumbs={true} />,
              },
              {
                path: Route.CREATE_APPRAISAL,
                element: (
                  <AppraisalProvider>
                    <CreateAppraisal />
                  </AppraisalProvider>
                ),
              },
              {
                path: Route.ORG_CHART,
                element: <OrgChart />,
              },
              {
                path: Route.MANAGE_APPRAISALS,
                children: [
                  {
                    path: Route.MANAGE_APPRAISALS_MY,
                    element: <ManageAppraisals employeesContext="me" />,
                  },
                  {
                    path: Route.MANAGE_APPRAISALS_TEAM,
                    element: <ManageAppraisals employeesContext="my-team" />,
                  },
                  {
                    path: Route.MANAGE_APPRAISALS_ALL,
                    element: <HasRole roles={['admin']} />,
                    children: [
                      {
                        path: Route.MANAGE_APPRAISALS_ALL,
                        element: <ManageAppraisals employeesContext="all" />,
                      },
                    ],
                  },
                  {
                    path: Route.MANAGE_APPRAISALS,
                    loader: () => redirect(Route.MANAGE_APPRAISALS_MY),
                  },
                ],
              },
              {
                // must be the last one
                path: Route.HOME,
                loader: () => redirect(Route.KRS),
              },
            ],
          },
        ],
      },
    ],
  },
]);
