import { Help } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { FormikContextType } from 'formik';

export interface KeyResultFormRankingProps {
  isDisabled?: boolean;
  form: FormikContextType<{ ranking_index?: number }>;
}

function KeyResultFormRanking({ isDisabled, form }: KeyResultFormRankingProps) {
  return (
    <FormControlLabel
      data-testid="ranking-index"
      control={
        <Checkbox
          disabled={isDisabled}
          checked={form.values.ranking_index === 1}
          onChange={(_, isChecked) => form.setFieldValue('ranking_index', isChecked ? 1 : 2)}
        />
      }
      label={
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography>This KR must be prioritized</Typography>

          <Tooltip title="If checked this KR will appear above the others and all changes will be automatically agreed">
            <Help color="action" fontSize="small" />
          </Tooltip>
        </Stack>
      }
    />
  );
}

export default KeyResultFormRanking;
