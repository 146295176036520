import { Container, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Breadcrumbs, Definition, NotFound, PageCard, PageLoading } from '../../components';
import { useGetEmployeeById } from '../../services/useGetEmployeeById';
import { Route } from '../../utils';
import toTitleCase from '../../utils/toTitleCase/toTitleCase';

function DetailEmployee() {
  const params = useParams();

  const { data: getEmployeeByIdRequest, loading: loadingEmployee } = useGetEmployeeById(params.id!);

  const employee = getEmployeeByIdRequest?.getEmployeeById;

  if (loadingEmployee) {
    return <PageLoading isLoading={true} />;
  }

  if (!employee) {
    return <NotFound item={'Employee'} />;
  }

  let employedAt = '';

  if (employee.employed_at) {
    try {
      const employmentDate = new Date(employee.employed_at);
      employedAt = `${employmentDate.getFullYear()}-${
        employmentDate.getMonth() + 1
      }-${employmentDate.getDate()}`;
    } catch {
      //let it fail
    }
  }

  let endDate = '';

  if (employee.endDate) {
    try {
      const employmentEndDate = new Date(Date.parse(employee.endDate.toString()));
      endDate = employmentEndDate.toISOString().slice(0, 10);
    } catch {
      //let it fail
    }
  }

  return (
    <Stack height="100%" justifyContent="center">
      <Container maxWidth="md">
        <Stack spacing={1.5}>
          <Breadcrumbs
            items={['Employee details']}
            backLink={{ label: 'Employees', to: Route.LIST_EMPLOYEES }}
          />

          <PageCard>
            <Typography fontSize="large">{'Employee details'}</Typography>

            <Stack spacing={2} alignItems={'center'} sx={{ mt: 4 }}>
              <Definition direction={'row'} label="Name" value={employee.name} />
              <Definition direction={'row'} label="Prefered Name" value={employee.preferredName} />
              <Definition direction={'row'} label="E-mail" value={employee.email} />
              <Definition
                direction={'row'}
                label="Registration ID"
                value={employee.registration_id}
              />
              <Definition
                direction={'row'}
                label="Direct Manager"
                value={employee.directManager?.preferredName}
              />
              <Definition
                direction={'row'}
                label="Job Title"
                value={toTitleCase(employee.position)}
              />
              <Definition
                direction={'row'}
                label="Business Unit"
                value={toTitleCase(employee.business_unit)}
              />
              <Definition
                direction={'row'}
                label="Department"
                value={toTitleCase(employee.cecoDescription)}
              />
              <Definition
                direction={'row'}
                label="Subdepartment"
                value={toTitleCase(employee.department)}
              />
              <Definition direction={'row'} label="Country" value={toTitleCase(employee.country)} />
              <Definition direction={'row'} label="CEO Level" value={employee.ceo_level} />
              <Definition
                direction={'row'}
                label="Seniority"
                value={toTitleCase(employee.seniority)}
              />
              <Definition direction={'row'} label={'Employed at'} value={employedAt} />
              <Definition direction={'row'} label={'End Date'} value={endDate} />
            </Stack>
          </PageCard>
        </Stack>
      </Container>
    </Stack>
  );
}

export default DetailEmployee;
