import { Avatar, AvatarGroup, List, Stack, Tooltip, Typography } from '@mui/material';

import { cropGoogleImage } from '../../../utils';

export interface Contributor {
  id: string;
  name: string;
  weight: number;
  weight_real?: number;
  type?: string;
  isActive?: boolean;
  employee?: {
    preferredName?: string;
    profilePicture?: string;
  };
}

export interface KeyResultCardContributorsProps {
  label?: string;
  contributors: Contributor[];
  highlightContributorId?: string;
}

function KeyResultCardContributors({
  label,
  contributors,
  highlightContributorId,
  ...props
}: KeyResultCardContributorsProps) {
  const highlightContributors = getHighlight(contributors, highlightContributorId);
  const nonHighlightContributors = getNonHighlight(contributors, highlightContributorId);
  const plusModifier = contributors.length > maxAvatars ? 1 : 0;
  const visibleContributorsCount = maxAvatars - highlightContributors.length - plusModifier;
  const visibleContributors = nonHighlightContributors.slice(0, visibleContributorsCount);
  const hiddenContributors = nonHighlightContributors.slice(visibleContributorsCount);

  return (
    <Stack {...props} spacing={1} direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body2" component="span" color="text.secondary">
        {label || 'Contributors'}:
      </Typography>

      <AvatarGroup
        max={maxAvatars}
        sx={{
          '& .MuiAvatar-root[data-highlight="false"]': {
            borderColor: 'grey.700',
          },
          '& .MuiAvatar-root[data-highlight="true"]': {
            borderColor: 'primary.main',
          },
          '& .MuiAvatar-root:not([data-highlight])': {
            ...avatarStyles,
            color: 'common.white',
            bgcolor: 'grey.700',
            borderColor: 'grey.700',
          },
        }}
      >
        {highlightContributors.map((contributor) => {
          return (
            <Tooltip key={contributor.id} title={formatTooltipTitle(contributor)}>
              <Avatar
                src={cropGoogleImage(24, contributor.employee?.profilePicture ?? '')}
                data-testid="contributor-item"
                alt={contributor.name}
                data-highlight="true"
                sx={{ ...avatarStyles, color: 'common.white', bgcolor: 'primary.main' }}
              >
                {formatContributorName(contributor)}
              </Avatar>
            </Tooltip>
          );
        })}

        {visibleContributors.map((contributor) => (
          <Tooltip key={contributor.id} title={formatTooltipTitle(contributor)}>
            <Avatar
              data-testid="contributor-item"
              data-highlight="false"
              sx={avatarStyles}
              src={cropGoogleImage(24, contributor.employee?.profilePicture ?? '')}
            >
              {formatContributorName(contributor)}
            </Avatar>
          </Tooltip>
        ))}

        {hiddenContributors.length && (
          <Tooltip
            title={hiddenContributors.map((contributor) => (
              <List key={contributor.id}>{formatTooltipTitle(contributor)}</List>
            ))}
          >
            <Avatar data-testid="contributor-item">+{hiddenContributors.length}</Avatar>
          </Tooltip>
        )}
      </AvatarGroup>
    </Stack>
  );
}

function getHighlight(contributors: Contributor[], highlightContributorId?: string) {
  const highlight =
    highlightContributorId && contributors.find(({ id }) => id === highlightContributorId);
  return highlight ? [highlight] : [];
}

function getNonHighlight(contributors: Contributor[], highlightContributorId?: string) {
  return contributors.filter((contributor) => contributor?.id !== highlightContributorId);
}

function formatTooltipTitle(contributor: Contributor) {
  const name = contributor.employee?.preferredName;

  if (contributor.isActive) {
    return name;
  }

  return `${name} (deactivated)`;
}

function formatContributorName(contributor: Contributor) {
  return contributor
    .employee!.preferredName!.split(' ')
    .slice(0, 2)
    .map((word) => word.charAt(0))
    .join('');
}

const avatarStyles = {
  width: '24px',
  height: '24px',
  color: 'grey.700',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  bgcolor: 'common.white',
};

const maxAvatars = 5;

export default KeyResultCardContributors;
