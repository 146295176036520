import { Employee, User } from '../../utils';
import { FormattedResponse } from '../service.utils';

export type EmployeeFormattedResponse<BObj> = FormattedResponse<
  EmployeeFormattedToMapper,
  BObj,
  EmployeeMappedFields
>;

export interface EmployeeFormattedToMapper extends EmployeeCommon {
  user?: User;
}
export interface EmployeeFormatted extends EmployeeCommon {
  user?: User;
}

interface EmployeeCommon {
  id: Employee['id'];
  name: Employee['name'];
  preferredName: Employee['preferredName'];
  email: Employee['email'];
  registrationId: Employee['registration_id'];
  reportsTo: Employee['reports_to'];
  position: Employee['position'];
  businessUnit: Employee['business_unit'];
  country: Employee['country'];
  ceoLevel: Employee['ceo_level'];
  department: Employee['department'];
  seniority: Employee['seniority'];
  employedAt: Employee['employed_at'];
  profilePicture: Employee['profilePicture'];
}

export interface EmployeeMappedFields {
  registrationId: 'registration_id';
  reportsTo: 'reports_to';
  businessUnit: 'business_unit';
  ceoLevel: 'ceo_level';
  employedAt: 'employed_at';
}

export interface EmployeeWithRelations extends Employee {}

export function formatEmployee(employee: Partial<EmployeeWithRelations> = {}) {
  return {
    ...formatId(employee.id),
    ...formatName(employee.name),
    ...formatPreferredName(employee.preferredName),
    ...formatEmail(employee.email),
    ...formatRegistrationId(employee.registration_id),
    ...formatReportsTo(employee.reports_to),
    ...formatPosition(employee.position),
    ...formatBusinessUnit(employee.business_unit),
    ...formatCountry(employee.country),
    ...formatCeoLevel(employee.ceo_level),
    ...formatDepartment(employee.department),
    ...formatSeniority(employee.seniority),
    ...formatEmployedAt(employee.employed_at),
    ...formatUser(employee.user),
    ...formatProfilePicture(employee.profilePicture),
  };
}

function formatId(id?: string) {
  return id ? { id } : null;
}
function formatUser(user?: User) {
  return user ? { user } : null;
}

function formatName(name?: string) {
  return name ? { name } : null;
}

function formatPreferredName(preferredName?: string) {
  return preferredName ? { preferredName } : null;
}

function formatEmail(email?: string) {
  return email ? { email } : null;
}

function formatRegistrationId(registrationId?: string) {
  return registrationId ? { registrationId } : null;
}

function formatReportsTo(reportsTo?: string) {
  return reportsTo ? { reportsTo } : null;
}

function formatPosition(position?: string) {
  return position ? { position } : null;
}

function formatBusinessUnit(businessUnit?: string) {
  return businessUnit ? { businessUnit } : null;
}

function formatCountry(country?: string) {
  return country ? { country } : null;
}

function formatCeoLevel(ceoLevel?: string) {
  return ceoLevel ? { ceoLevel } : null;
}

function formatDepartment(department?: string) {
  return department ? { department } : null;
}

function formatSeniority(seniority?: string) {
  return seniority ? { seniority } : null;
}

function formatEmployedAt(employedAt?: Date) {
  return employedAt ? { employedAt } : null;
}

function formatProfilePicture(profilePicture?: string) {
  return profilePicture ? { profilePicture } : null;
}
