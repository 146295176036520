import { Backdrop, CircularProgress } from '@mui/material';

interface PageLoadingProps {
  isLoading: boolean;
}

function PageLoading({ isLoading }: PageLoadingProps) {
  return (
    <Backdrop
      open={isLoading}
      sx={{
        color: (theme) => theme.palette.primary.dark,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress data-testid="page-loading" color="inherit" size={80} />
    </Backdrop>
  );
}

export default PageLoading;
