import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Route } from '../../utils';

export interface NotFoundProps {
  item?: string;
}

function NotFound({ item }: NotFoundProps) {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center" spacing={4}>
      <Typography variant="h4" align="center" color="primary">
        The requested {item || 'item'} was not found.
      </Typography>

      <Button variant="contained" component={Link} to={Route.HOME}>
        Back to homepage
      </Button>
    </Stack>
  );
}

export default NotFound;
