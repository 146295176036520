import { Stack, TableCell, Typography } from '@mui/material';
import { Fragment } from 'react';

import { showWeightMultiplierFriendly } from '../../../services/mappers/keyResult.mapper';
import HelpTooltip from '../../HelpTooltip/HelpTooltip';
import KeyResultStatus from '../../KeyResultStatus/KeyResultStatus';
import KeyResultCardContributors from '../Commons/KeyResultCardContributors';
import KeyResultCardDescription from '../Commons/KeyResultCardDescription';
import KeyResultCardFocusArea from '../Commons/KeyResultCardFocusArea';
import KeyResultCardName from '../Commons/KeyResultCardName';
import KeyResultCardObjective from '../Commons/KeyResultCardObjective';
import { KeyResultCardProps } from '../KeyResultCard.types';

function KeyResultCardTable(props: KeyResultCardProps) {
  return (
    <Fragment>
      <TableCell
        align="center"
        sx={{
          ...(props.agreement !== undefined && {
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              width: '5px',
              bgcolor: props.agreement ? 'success.main' : 'error.light',
              transform: ({ spacing }) => `translateX(-${spacing(3)})`,
            },
          }),
        }}
      >
        <KeyResultCardFocusArea focusArea={props.focusArea} />
      </TableCell>

      <TableCell>
        {Boolean(props.objective) && (
          <KeyResultCardObjective
            name={props.objective!.name}
            description={props.objective!.description}
          />
        )}
      </TableCell>

      <TableCell>
        <KeyResultCardName name={props.name} isClosed={props.isClosed} />
      </TableCell>

      <TableCell>
        <KeyResultCardDescription description={props.howToCalculateMetric} />
      </TableCell>

      <TableCell align="center">
        {Boolean(props.status) && <KeyResultStatus status={props.status!} />}
      </TableCell>

      <TableCell
        align="center"
        sx={{
          // TODO: remove me when extracts the AvatarGroup component from
          // KeyResultCardContributors to its own component
          '& > *': {
            justifyContent: 'center',
            '& > .MuiTypography-root': { display: 'none' },
            '& > .MuiAvatarGroup-root': { marginLeft: 0 },
          },
        }}
      >
        <KeyResultCardContributors
          contributors={props.contributors}
          highlightContributorId={props.contributors.find((c) => c.type === 'owner')?.id}
        />
      </TableCell>

      <TableCell align="center">
        {typeof props.weights?.target === 'number' && (
          <Typography data-testid="target-weight" fontSize="1.125rem" fontWeight="bold">
            {props.weights.target}
          </Typography>
        )}
      </TableCell>

      <TableCell align="center">
        {props.weights?.showReal && (
          <Stack direction="row" justifyContent="center" spacing={0.5}>
            <Typography data-testid="real-weight" fontSize="1.125rem" fontWeight="bold">
              {props.weights.real ?? 0}
            </Typography>

            {typeof props.weights.multiplier === 'number' &&
              typeof props.weights.target === 'number' && (
                <HelpTooltip
                  title={`${props.weights.target} x ${showWeightMultiplierFriendly(
                    props.weights.multiplier
                  )} = ${props.weights.real}`}
                  fontSize="0.9rem"
                />
              )}
          </Stack>
        )}
      </TableCell>

      {props.actions && <TableCell>{props.actions}</TableCell>}
    </Fragment>
  );
}

export default KeyResultCardTable;
