import { ContributorWithRelations } from './contributor.mapper';
import { Contributor, ContributorAgreement, Leaves } from '../../utils';

export type RawContributor = Contributor;

export type AgreementsFormattedResponse<
  BObj,
  BObjKeys = Leaves<BObj>
> = AgreementsRequiredFields extends BObjKeys ? Record<string, boolean | undefined> : never;

export type AgreementsRequiredFields = 'contributors.agreement' | 'contributors.user_id';

export function formatAgreements(contributors: RawContributor[]) {
  return contributors.reduce(function (prev, curr) {
    return { ...prev, [curr.user_id]: formatAgreementValue(curr.agreement) };
  }, {} as Record<string, boolean | undefined>);
}

function formatAgreementValue(agreement?: ContributorAgreement): boolean | undefined {
  if (agreement === undefined || agreement === 'no-action') {
    return undefined;
  }

  return agreement === 'agree';
}

export function formatAgreementCount(rawContributors: ContributorWithRelations[]): AgreementCount {
  return rawContributors.reduce(
    function (prev, curr) {
      const agreement = curr.agreement;

      if (!agreement) {
        return { ...prev, 'no-action': prev['no-action'].concat(curr.employee!.preferredName!) };
      }

      return { ...prev, [agreement]: prev[agreement].concat(curr.employee!.preferredName!) };
    },
    { agree: [], disagree: [], 'no-action': [] } as AgreementCount
  );
}

type AgreementCount = Record<ContributorAgreement, string[]>;
