import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function usePagination(itemsCount: number, limit = PAGINATION_LIMIT) {
  const [queryString, setQueryString] = useSearchParams({ page: '1' });

  const setPage = useCallback(
    function (page: number) {
      setQueryString((prev) => {
        const pageCount = calculatePageCount(itemsCount, limit);
        const newPage = calculatePage(page, pageCount);

        const qs = new URLSearchParams(prev);
        qs.set('page', String(newPage));

        return qs;
      });
    },
    [itemsCount, limit, setQueryString]
  );

  return useMemo(
    function () {
      const pageCount = calculatePageCount(itemsCount, limit);
      const page = calculatePage(Number(queryString.get('page')), pageCount);
      const offset = calculateOffset(page, limit);

      return {
        page,
        pageCount,
        offset,
        limit,
        setPage,
      };
    },
    [itemsCount, limit, queryString, setPage]
  );
}

export const PAGINATION_LIMIT = 10;

function calculatePageCount(itemsCount: number, limit: number) {
  return Math.max(Math.ceil(itemsCount / limit), 1);
}

function calculatePage(page: number, pageCount: number) {
  if (page < 1 || isNaN(page)) {
    return 1;
  }

  return Math.min(page, pageCount);
}

function calculateOffset(page: number, limit: number) {
  const pageZeroBased = page - 1;

  return pageZeroBased * limit;
}
