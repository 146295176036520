import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { UserEmployee } from '../utils';

const limit = 10;

interface GetUsersResult {
  getUsers: UserEmployee[];
}

export function useGetUsers(page?: number, name?: string) {
  const { enqueueSnackbar } = useSnackbar();
  const options: QueryHookOptions = {
    fetchPolicy: 'no-cache',
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  };
  options.variables = { filter: { name: name || '' } };
  if (page) {
    options.variables = { ...options.variables, offset: (page - 1) * limit, limit };
  }
  const result = useQuery<GetUsersResult>(getUsersQuery, options);

  return result;
}

export const getUsersQuery = gql`
  query ($offset: Int, $limit: Int, $filter: UserFilter) {
    getUsers(offset: $offset, limit: $limit, filter: $filter) {
      id
      name
      email
      is_active
      profile_picture
      employee {
        id
        email
        name
        preferredName
      }
    }
  }
`;
