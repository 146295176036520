import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import { PageLoading } from '../../components';
import KeyResultForm, {
  KeyResultFormOnSubmitValues,
  KeyResultFormType,
} from '../../components/KeyResultForm/KeyResultForm';
import { useContext, userContext } from '../../context';
import { useGetKeyResultById, useGetObjectives, useGetUsers } from '../../services';
import { useUpdateKeyResult } from '../../services/useUpdateKeyResult';
import { FocusArea, Route } from '../../utils';

function EditKeyResult() {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isAdmin } = useContext(userContext);

  const { data: getKeyResultByIdRequest, loading: loadingKeyResult } = useGetKeyResultById([
    params.id!,
  ]);
  const { data: getUsersRequest, loading: loadingUsers } = useGetUsers();
  const { data: getObjectivesRequest, loading: loadingObjectives } = useGetObjectives();

  const [createKeyResult] = useUpdateKeyResult({
    onCompleted: (data) => {
      enqueueSnackbar('Key Result edited successfully', { variant: 'success' });
      navigate(Route.OBJECTIVES_DETAILS.replace(':id', keyResult ? keyResult.objective.id : ''));
    },
  });
  const keyResult = getKeyResultByIdRequest.getKeyResults[0];
  const defaultDeadline = new Date(`${new Date().getFullYear()}-12-31`);
  const initialValues = {
    id: keyResult?.id || '',
    objective_id: keyResult?.objective.id || '',
    name: keyResult?.name || '',
    how_to_calculate_metric: keyResult?.description || '',
    focus_area: keyResult?.focusArea || ('' as FocusArea),
    deadline: keyResult?.deadline ? new Date(keyResult.deadline) : defaultDeadline,
    fiscal_year: keyResult?.fiscalYear || '',
    contributors: (keyResult?.contributors ?? []).filter((c) => c.id !== keyResult.owner?.id),
    weight_multiplier: keyResult?.weight_multiplier || 0,
    owner: keyResult?.owner,
    ownerWeight: keyResult?.owner?.weight,
    bulkContributors: [],
    ranking_index: keyResult?.rankingIndex,
    archived_at: keyResult?.archivedAt,
    is_closed: keyResult?.isClosed || false,
  };

  const onSubmit = function (
    {
      id,
      objective_id,
      name,
      how_to_calculate_metric,
      focus_area,
      deadline,
      contributors,
      archived_at,
      ranking_index,
      is_closed,
    }: KeyResultFormOnSubmitValues,
    { setSubmitting }: any
  ) {
    createKeyResult({
      variables: {
        id,
        objective_id,
        name,
        how_to_calculate_metric,
        status: keyResult?.status,
        focus_area,
        deadline,
        ranking_index,
        contributors: contributors.map((c) => ({ ...c, weight: String(c.weight) })),
        weight_multiplier: keyResult?.weight_multiplier || 0,
        archived_at: archived_at || null,
        is_closed,
      },
    }).finally(() => setSubmitting(false));
  };

  if (loadingUsers || loadingObjectives || loadingKeyResult) {
    return <PageLoading isLoading={true} />;
  }

  const isDisabled = keyResult.isClosed && !isAdmin;

  return (
    <KeyResultForm
      formType={KeyResultFormType.edit}
      parentObjective={keyResult?.objective}
      objectives={getObjectivesRequest?.getObjectives}
      users={getUsersRequest?.getUsers}
      onSubmit={onSubmit}
      initialValues={initialValues}
      isDisabled={isDisabled}
    />
  );
}

export default EditKeyResult;
