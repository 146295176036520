import OrgChartIcon from '@mui/icons-material/AccountTree';
import BadgeIcon from '@mui/icons-material/Badge';
import AppraisalIcon from '@mui/icons-material/Description';
import HowToReg from '@mui/icons-material/HowToReg';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import UserIcon from '@mui/icons-material/Person';
import ObjectiveIcon from '@mui/icons-material/PlaylistAddCheck';

import { PageLayoutListItemProps } from './PageLayoutListItem';
import { Route } from '../../utils';

export const items: PageLayoutListItemProps[] = [
  {
    icon: ObjectiveIcon,
    label: 'Objectives',
    to: Route.LIST_OBJECTIVES,
  },
  {
    icon: HowToReg,
    label: 'Key results',
    to: Route.KRS,
  },
  {
    icon: AppraisalIcon,
    label: 'My appraisals',
    to: Route.MANAGE_APPRAISALS_MY,
    reloadDocument: true,
  },
  {
    icon: AppraisalIcon,
    label: 'Team appraisals',
    to: Route.MANAGE_APPRAISALS_TEAM,
    reloadDocument: true,
  },
  {
    icon: AppraisalIcon,
    label: 'Manage all appraisals',
    to: Route.MANAGE_APPRAISALS_ALL,
    reloadDocument: true,
  },
  {
    icon: OrgChartIcon,
    label: 'Organogram',
    to: Route.ORG_CHART,
  },
  {
    icon: BadgeIcon,
    label: 'Employees',
    to: Route.LIST_EMPLOYEES,
  },
  {
    icon: UserIcon,
    label: 'Users',
    to: Route.LIST_USERS,
    is_admin: true,
  },
  {
    icon: KeyIcon,
    label: 'Change password',
    to: Route.CHANGE_PASSWORD,
    is_admin: false,
  },
  {
    icon: LogoutIcon,
    label: 'Logout',
    to: Route.LOGOUT,
    is_admin: false,
  },
];
