import { Typography } from '@mui/material';

export interface KeyResultCardDescriptionProps {
  description: string;
}

function KeyResultCardDescription(props: KeyResultCardDescriptionProps) {
  return (
    <Typography
      color="text.secondary"
      fontSize="0.875rem"
      letterSpacing="0.25px"
      whiteSpace="pre-line"
    >
      {props.description}
    </Typography>
  );
}

export default KeyResultCardDescription;
