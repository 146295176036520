import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

interface GetKeyResultFiscalYearsByUserIdResult {
  getKeyResultFiscalYearsByUserId: string[];
}

interface GetKeyResultFiscalYearsByUserIdOptions
  extends Omit<QueryHookOptions<GetKeyResultFiscalYearsByUserIdResult>, 'onCompleted'> {
  onCompleted?: (years: string[]) => void;
}

export function useGetKeyResultFiscalYearsByUserId({
  onCompleted,
  ...options
}: GetKeyResultFiscalYearsByUserIdOptions) {
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, ...result } = useQuery<GetKeyResultFiscalYearsByUserIdResult>(
    getKeyResultFiscalYearsByUserIdQuery,
    {
      onError: function (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted(data) {
        onCompleted && onCompleted(formatFiscalYears(data?.getKeyResultFiscalYearsByUserId));
      },
      ...options,
    }
  );

  const mappedData = loading ? [] : formatFiscalYears(data?.getKeyResultFiscalYearsByUserId);

  return { data: { getKeyResultFiscalYearsByUserId: mappedData }, loading, ...result };
}

function formatFiscalYears(years?: string[]) {
  return years?.length ? years : [String(new Date().getFullYear())];
}

export const getKeyResultFiscalYearsByUserIdQuery = gql`
  query ($userId: String!) {
    getKeyResultFiscalYearsByUserId(user_id: $userId)
  }
`;
