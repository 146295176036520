import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

interface CreateKeyResultLikeResult {
  createKeyResultLike: { id: string };
}

export function useCreateKeyResultLike() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useMutation<CreateKeyResultLikeResult>(createKeyResultLikeMutation, {
    variables: { type: 'like' },
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return result;
}

export const createKeyResultLikeMutation = gql`
  mutation CreateLike($krId: String!, $payload: KeyResultLike!) {
    createKeyResultLike(kr_id: $krId, payload: $payload) {
      id
    }
  }
`;
