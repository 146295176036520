import { Stack, TextField, Typography } from '@mui/material';
import { FormikContextType } from 'formik';

import { ContributorFormatted } from '../../services/mappers/contributor.mapper';
import { UserEmployee } from '../../utils';
import UserAutocomplete from '../SearchFieldsAutoComplete/UserAutocomplete/UserAutocomplete';

interface KeyResultFormOwnerProps {
  users: UserEmployee[];
  form: FormikContextType<{
    owner?: ContributorFormatted;
    ownerWeight?: ContributorFormatted['weight'];
  }>;
}

function KeyResultFormOwner({ form, users }: KeyResultFormOwnerProps) {
  return (
    <>
      <Typography variant="h6">Owner</Typography>

      <Stack data-testid="owner-form" direction="row" spacing={2}>
        <UserAutocomplete
          options={users}
          value={form.values.owner ?? (null as any)}
          onChange={(_, value) => form.setFieldValue('owner', value)}
          inputProps={{ label: 'User' }}
        />

        <TextField
          label="Weight"
          name="ownerWeight"
          value={form.values.ownerWeight}
          onChange={form.handleChange}
        />
      </Stack>
    </>
  );
}

export default KeyResultFormOwner;
