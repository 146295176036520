import NiceModal, { muiDialogV5, useModal } from '@ebay/nice-modal-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default NiceModal.create(function () {
  const modal = useModal();

  return (
    <Dialog {...muiDialogV5(modal)}>
      <DialogTitle>Pending contributors</DialogTitle>

      <DialogContent>
        <DialogContentText>
          There are some groups of potential contributors that need to be added as contributors or
          to be discarded.
        </DialogContentText>

        <DialogContentText marginTop={4}>
          Please resolve all groups before moving on.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={modal.hide}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
});
