import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { styles } from '../../utils';
import KeyResultCard from '../KeyResultCard/KeyResultCard';
import { KeyResultCardProps, KeyResultCardVariants } from '../KeyResultCard/KeyResultCard.types';

export const KEY_RESULT_CARD_LIST_VARIANTS = ['list-details', 'table'] as const;
export type KeyResultCardListVariants = (typeof KEY_RESULT_CARD_LIST_VARIANTS)[number];

export interface KeyResultCardListProps {
  variant: KeyResultCardListVariants;
  keyResults: KeyResult[];
  onClick?: (keyResult: KeyResult) => void;
}

export type KeyResult = Omit<KeyResultCardProps, 'variant'> & { id: string };
type ListVariant = 'list' | 'table';

function KeyResultCardList(props: KeyResultCardListProps) {
  const listVariant = listVariants[props.variant];
  const cardVariant = cardVariants[props.variant];

  switch (listVariant) {
    case 'list':
      return (
        <Stack spacing={2}>
          {props.keyResults.map((keyResult) => (
            <Box
              key={keyResult.id}
              onClick={() => props.onClick?.(keyResult)}
              sx={
                props.onClick && {
                  cursor: 'pointer',
                  borderRadius: ({ spacing }) => spacing(1),
                  ...styles.selectableItem,
                }
              }
            >
              <KeyResultCard variant={cardVariant} {...keyResult} />
            </Box>
          ))}
        </Stack>
      );
    case 'table':
      if (props.keyResults.length === 0) {
        return null;
      }

      const hasSomeAction = props.keyResults.some(({ actions }) => Boolean(actions));

      return (
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <TableCell
                    data-testid="kr-table-header"
                    key={column.label}
                    align={column.align}
                    sx={column.maxWidth && { maxWidth: column.maxWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {props.keyResults.map((keyResult) => (
                <TableRow
                  hover
                  data-testid={`key-result-card-${cardVariant}`}
                  key={keyResult.id}
                  onClick={() => props.onClick?.(keyResult)}
                  sx={props.onClick && { cursor: 'pointer' }}
                >
                  <KeyResultCard
                    variant={cardVariant}
                    {...keyResult}
                    actions={hasSomeAction ? keyResult.actions ?? <div /> : undefined}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
  }
}

const listVariants: Record<KeyResultCardListVariants, ListVariant> = {
  'list-details': 'list',
  table: 'table',
};
const cardVariants: Record<KeyResultCardListVariants, KeyResultCardVariants> = {
  'list-details': 'details',
  table: 'table',
};

const tableColumns = [
  { label: 'Focus', align: 'center', maxWidth: undefined },
  { label: 'Objective', align: 'left', maxWidth: undefined },
  { label: 'Key result', align: 'left', maxWidth: undefined },
  { label: 'Description', align: 'left', maxWidth: undefined },
  { label: 'Status', align: 'center', maxWidth: undefined },
  { label: 'Contributors', align: 'center', maxWidth: undefined },
  { label: 'Target weight', align: 'center', maxWidth: '90px' },
  { label: 'Real weight', align: 'center', maxWidth: '90px' },
  { label: 'Actions', align: 'center', maxWidth: undefined },
] as const;

export default KeyResultCardList;
