import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useContext, userContext } from '../context';
import { useLocalStorage } from '../hooks';
import { Employee, LocalStorageKeys } from '../utils';

interface LoginResult {
  login: {
    token: string;
    user: {
      id: string;
      email: string;
      name: string;
      force_password_change_flag: boolean;
      roles: string[];
      profilePicture?: string;
      employee?: Employee;
    };
  };
}

interface LoginVariables {
  email: string;
  password: string;
}

export function useLogin(options?: MutationHookOptions<LoginResult, LoginVariables>) {
  const { enqueueSnackbar } = useSnackbar();
  const [, setToken] = useLocalStorage(LocalStorageKeys.TOKEN);
  const { setUser: setUserContext } = useContext(userContext);

  return useMutation<LoginResult, LoginVariables>(loginMutation, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: function (data) {
      if (data?.login?.token) {
        const rawUser = data.login.user;
        const mappedUser = {
          id: rawUser.id,
          name: rawUser.name,
          email: rawUser.email,
          forcePasswordChangeFlag: rawUser.force_password_change_flag,
          roles: rawUser.roles,
          employee: {
            ...rawUser.employee,
            id: rawUser.employee!.id,
            preferredName: rawUser.employee!.preferredName!,
          },
        };

        setToken(data.login.token);
        setUserContext(mappedUser);
      }

      options?.onCompleted?.(data);
    },
  });
}

export const loginMutation = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        name
        force_password_change_flag
        roles
        reports_to
        employee {
          id
          preferredName
          profilePicture
        }
      }
    }
  }
`;
