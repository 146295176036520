import OpenIcon from '@mui/icons-material/East';
import CloseIcon from '@mui/icons-material/West';
import { Box } from '@mui/material';

export interface PageLayoutArrowProps {
  isOpen: boolean;
  spacing: number;
  onClick: () => void;
}

function PageLayoutArrow(props: PageLayoutArrowProps) {
  const MenuIcon = props.isOpen ? CloseIcon : OpenIcon;

  return (
    <Box
      data-testid="menu-button"
      onClick={props.onClick}
      sx={{
        height: '50px',
        paddingRight: 0.5,
        position: 'absolute',
        top: ({ spacing }) => spacing(props.spacing),
        left: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.secondary',
        fontSize: '16px',
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'border.default',
        borderLeft: 0,
        borderRadius: 2,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        cursor: 'pointer',
      }}
    >
      <MenuIcon fontSize="inherit" />
    </Box>
  );
}

export default PageLayoutArrow;
