import NiceModal from '@ebay/nice-modal-react';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Divider,
  Chip,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { addYears, endOfYear, parseISO } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as yup from 'yup';

import KeyResultFormContributors from './KeyResultFormContributors';
import KeyResultFormIsClosed from './KeyResultFormIsClosed';
import KeyResultFormModal from './KeyResultFormModal';
import KeyResultFormOwner from './KeyResultFormOwner';
import KeyResultFormRanking from './KeyResultFormRanking';
import { ArchiveKrModal } from '../../components';
import { useGetKeyResultsName } from '../../services';
import { ContributorFormatted } from '../../services/mappers/contributor.mapper';
import { FOCUS_AREAS, FocusArea, Objective, Route, UserEmployee } from '../../utils';
import getIconAndCountryName from '../../utils/getFocusAreaIconAndCountryName/getIconAndCountryName';
import { Breadcrumbs, PageCard, PageLoading } from '../index';

export interface KeyResultFormValues {
  id?: string;
  objective_id: string;
  name: string;
  how_to_calculate_metric: string;
  focus_area: FocusArea | '';
  deadline: Date;
  fiscal_year: string;
  contributors: ContributorFormatted[];
  weight_multiplier?: number;
  owner?: ContributorFormatted;
  ownerWeight?: ContributorFormatted['weight'];
  archived_at?: Date;
  bulkContributors: Array<{
    contributors: Array<UserEmployee & { weight: ContributorFormatted['weight'] }>;
    weight: ContributorFormatted['weight'];
  } | null>;
  ranking_index?: number;
  is_closed: boolean;
}

export enum KeyResultFormType {
  edit = 'edit',
  create = 'create',
}

export interface KeyResultFormProps {
  formType: KeyResultFormType;
  parentObjective?: Objective;
  users?: UserEmployee[];
  objectives?: Objective[];
  initialValues: KeyResultFormValues;
  isDisabled?: boolean;
  onSubmit: (
    values: KeyResultFormOnSubmitValues,
    helpers: { setSubmitting: (toggle: boolean) => void }
  ) => void;
}

export type KeyResultFormOnSubmitValues = Omit<KeyResultFormValues, 'contributors'> & {
  contributors: Array<{ user_id: string; type: string; weight: number }>;
};

function KeyResultForm({
  formType,
  parentObjective,
  users,
  objectives,
  onSubmit,
  initialValues,
  isDisabled,
}: KeyResultFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = yup.object({
    objective_id: yup
      .string()
      .required('Please enter the objective to which this Key Result belongs to'),
    name: yup.string().required('Please enter the Key Result name'),
    how_to_calculate_metric: yup.string().required('Please enter a description for the Key Result'),
    focus_area: yup.string().required('Please enter the Key Result focus area '),
    deadline: yup.date().required('Please enter the Key Result deadline'),
    fiscal_year: yup.string().required('Please enter the Key Result fiscal_year'),
    owner: yup.object().required('Please select the owner'),
    ownerWeight: yup.number().required('Please enter the owner weight'),
    contributors: yup
      .array()
      .test(
        'validate contributor',
        'You need to fill all contributor fields or remove it',
        (contributors) => {
          if (contributors) {
            for (const contributor of contributors) {
              if (!contributor || contributor.weight === '0' || !contributor.id) {
                return false;
              }
            }
          }
          return true;
        }
      ),
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [archiveKr, setArchiveKr] = useState<boolean>(false);

  const handleAgreeArchive = () => {
    setArchiveKr(true);
    setOpenModal(false);
    formik.handleSubmit();
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchema,
    initialValues,
    validate: function (args) {
      const errors = {} as any;
      validationSchema.validate(args, { abortEarly: false }).catch((err) => {
        for (const error of err.errors) {
          enqueueSnackbar(error, { variant: 'error' });
        }
      });
      return errors;
    },
    onSubmit: function (data, helpers) {
      if (archiveKr) {
        initialValues.archived_at
          ? (data.archived_at = undefined)
          : (data.archived_at = new Date());
      }

      if (data.bulkContributors.length > 0) {
        helpers.setSubmitting(false);
        NiceModal.show(KeyResultFormModal);

        return;
      }

      const owner = {
        user_id: data.owner!.id,
        weight: data.ownerWeight!,
        type: 'owner',
      };
      const contributors = data.contributors.map((contributor) => ({
        user_id: contributor.id,
        weight: contributor.weight,
        type: 'contributor',
      }));

      onSubmit({ ...data, contributors: [owner].concat(contributors) }, helpers);
    },
  });

  const { data: keyResultsData } = useGetKeyResultsName();
  const keyResults = keyResultsData?.getKeyResults ?? [];

  let formTitle = '';
  let backlink = { label: 'Key Result', to: '/' };

  switch (formType) {
    case KeyResultFormType.create:
      formTitle = 'Create Key Result';
      backlink = {
        label: `Objective: ${parentObjective?.name}`,
        to: Route.LIST_OBJECTIVES + `/${parentObjective?.id}`,
      };
      break;
    case KeyResultFormType.edit:
      formTitle = 'Edit Key Result';
      backlink = {
        label: `${initialValues.name}`,
        to: Route.OBJECTIVES_DETAILS.replace(':id', parentObjective?.id || ''),
      };
      break;
  }

  return (
    <FormikProvider value={formik}>
      <Stack height="100%" justifyContent="center">
        <Container maxWidth="md">
          <PageLoading isLoading={formik.isSubmitting} />

          <Stack spacing={1.5}>
            <Breadcrumbs items={[formTitle]} backLink={backlink} />

            <PageCard>
              <Typography fontSize="large">{formTitle}</Typography>

              <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 4 }}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Autocomplete
                      disabled
                      fullWidth
                      options={objectives || []}
                      getOptionLabel={(option) => option.name} // IMPROVE: Should add fiscal year to differentiate multiple objectives with same name
                      renderInput={(params) => (
                        <TextField {...params} label="Objective" name={'objective_id'} />
                      )}
                      value={objectives?.find(
                        (objective) => objective.id === formik.values.objective_id
                      )}
                      onChange={(event, newValue) =>
                        formik.setFieldValue(`objective_id`, newValue ? newValue.id : '')
                      }
                    />

                    <TextField
                      id="fiscal_year"
                      name="fiscal_year"
                      type="text"
                      label="Fiscal Year"
                      onChange={formik.handleChange}
                      value={formik.values.fiscal_year}
                      disabled
                      fullWidth
                    />
                  </Stack>

                  <Autocomplete
                    freeSolo
                    fullWidth
                    disableClearable
                    options={getUniqueOptions(keyResults)}
                    onChange={(_event, newValue) => formik.setFieldValue('name', newValue)}
                    value={formik.values.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="What will you accomplish?"
                        name="name"
                        onChange={formik.handleChange}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />

                  <TextField
                    id="how_to_calculate_metric"
                    name="how_to_calculate_metric"
                    type="text"
                    label="How success will look like?"
                    onChange={formik.handleChange}
                    value={formik.values.how_to_calculate_metric}
                    minRows={2}
                    maxRows={5}
                    multiline
                    fullWidth
                  />

                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      select
                      id="focus_area"
                      name="focus_area"
                      type="text"
                      label="Focus Area"
                      onChange={formik.handleChange}
                      value={formik.values.focus_area}
                      fullWidth
                    >
                      {FOCUS_AREAS.map((fa) => {
                        const countryData = getIconAndCountryName(fa);
                        return (
                          <MenuItem key={fa} value={fa}>
                            <Box
                              data-testid="focus-area"
                              component="img"
                              src={countryData?.FlagIcon}
                              alt={`${fa}-flag`}
                              sx={{
                                width: '28px',
                                height: '20px',
                                marginTop: '-4px',
                                marginRight: '5px',
                                verticalAlign: 'middle',
                              }}
                            />
                            {countryData?.countryName}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <MobileDatePicker
                      label="Deadline"
                      inputFormat="MM/yyyy"
                      minDate={parseISO('2022-01-01')}
                      maxDate={endOfYear(addYears(new Date(), 1))}
                      value={formik.values.deadline}
                      onChange={(value) =>
                        formik.setFieldValue(
                          'deadline',
                          value && new Date(value.getFullYear(), value.getMonth() + 1, 0)
                        )
                      }
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      DialogProps={{ 'data-testid': 'date-picker', fullWidth: false } as any}
                      openTo="month"
                      views={['month', 'year']}
                    />
                  </Stack>

                  <KeyResultFormRanking isDisabled={isDisabled} form={formik as any} />

                  <KeyResultFormIsClosed isDisabled={isDisabled} form={formik as any} />

                  <KeyResultFormOwner form={formik as any} users={users ?? []} />

                  <KeyResultFormContributors
                    form={formik as any}
                    users={users ?? []}
                    isDisabled={isDisabled}
                  />

                  <Button
                    data-testid="create-kr-button"
                    type="submit"
                    size="large"
                    variant="contained"
                    disabled={isDisabled}
                  >
                    Save
                  </Button>
                  {formType === KeyResultFormType.edit && (
                    <>
                      <Divider>
                        <Chip label="DANGER ZONE" />
                      </Divider>

                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={8}
                      >
                        <Button fullWidth disabled={isDisabled} onClick={() => setOpenModal(true)}>
                          {initialValues.archived_at
                            ? 'Unarchive Key Result'
                            : 'Archive Key Result'}
                        </Button>
                      </Stack>

                      <ArchiveKrModal
                        open={openModal}
                        data-testid="archive-button"
                        onAgree={handleAgreeArchive}
                        onCancel={() => setOpenModal(false)}
                        textArchived={initialValues.archived_at ? 'unarchive' : 'archive'}
                        onClose={() => {
                          setOpenModal(false);
                        }}
                      />
                    </>
                  )}
                  <Divider />
                </Stack>
              </Box>
            </PageCard>
          </Stack>
        </Container>
      </Stack>
    </FormikProvider>
  );
}

interface Option {
  id: string;
  name: string;
}

function getUniqueOptions(options: Option[]) {
  const names = options.map((option) => option.name);

  return Array.from(new Set(names));
}

export default KeyResultForm;
