import { parseISO } from 'date-fns';

import {
  ContributorFormatted,
  ContributorFormattedToMapper,
  ContributorMappedFields,
  ContributorWithRelations,
  formatContributors,
  formatOwner,
} from './contributor.mapper';
import {
  KeyResultUpdateFormatted,
  KeyResultUpdateFormattedToMapper,
  KeyResultUpdateMappedFields,
  KeyResultUpdateWithRelations,
  formatKeyResultUpdates,
} from './keyResultUpdate.mapper';
import {
  ObjectiveFormatted,
  ObjectiveFormattedToMapper,
  ObjectiveMappedFields,
  formatObjective,
} from './objective.mapper';
import { KeyResult, FocusArea, isNotDefined, Objective, UserEmployee } from '../../utils';
import { FormattedResponse } from '../service.utils';

export type KeyResultFormattedResponse<BObj> = FormattedResponse<
  KeyResultFormattedToMapper,
  BObj,
  KeyResultMappedFields
>;

export interface KeyResultFormattedToMapper extends KeyResultCommon {
  objective: [ObjectiveFormattedToMapper, ObjectiveMappedFields];
  appraisals: [KeyResultUpdateFormattedToMapper[], KeyResultUpdateMappedFields];
  updates: [KeyResultUpdateFormattedToMapper[], KeyResultUpdateMappedFields];
  contributors: [ContributorFormattedToMapper[], ContributorMappedFields];
  owner: [ContributorFormattedToMapper, ContributorMappedFields, 'contributors'];
}

export interface KeyResultFormatted extends KeyResultCommon {
  objective?: Partial<ObjectiveFormatted>;
  appraisals?: Array<Partial<KeyResultUpdateFormatted>>;
  updates?: Array<Partial<KeyResultUpdateFormatted>>;
  contributors?: Array<Partial<ContributorFormatted>>;
  owner?: Partial<ContributorFormatted>;
}

export interface KeyResultCommon {
  id: KeyResult['id'];
  name: KeyResult['name'];
  description: KeyResult['how_to_calculate_metric'];
  status: KeyResult['status'];
  deadline: Date;
  lastUpdate: KeyResult['updatedAt'];
  fiscalYear: KeyResult['fiscal_year'];
  focusArea: KeyResult['focus_area'];
  weightMultiplier: KeyResult['weight_multiplier'];
  rankingIndex?: KeyResult['ranking_index'];
  isClosed?: KeyResult['is_closed'];
  agreements: Record<string, boolean | undefined>;
}

export interface KeyResultMappedFields {
  description: 'how_to_calculate_metric';
  lastUpdate: 'updatedAt';
  fiscalYear: 'fiscal_year';
  focusArea: 'focus_area';
  weightMultiplier: 'weight_multiplier';
  rankingIndex: 'ranking_index';
  isClosed: 'is_closed';
  owner: 'contributors.user_type';
  agreements: 'contributors.agreement' | 'contributors.user_id';
}

export interface KeyResultWithRelations extends KeyResult {
  objective?: Partial<Objective>;
  appraisals?: Array<Partial<KeyResultUpdateWithRelations>>;
  updates?: Array<Partial<KeyResultUpdateWithRelations>>;
  contributors?: Array<Partial<ContributorWithRelations>>;
}

export function formatKeyResult(
  loggedUser: UserEmployee,
  kr: Partial<KeyResultWithRelations> = {}
) {
  return {
    ...formatId(kr.id),
    ...formatName(kr.name),
    ...formatDescription(kr.how_to_calculate_metric),
    ...formatStatus(kr.status),
    ...formatDeadline(kr.deadline),
    ...formatLastUpdate(kr.updatedAt),
    ...formatFiscalYear(kr.fiscal_year),
    ...formatFocusArea(kr.focus_area),
    ...formatWeightMultiplier(kr.weight_multiplier),
    ...formatRankingIndex(kr.ranking_index),
    ...formatIsClosed(kr.is_closed),
    ...formatAgreements(kr.contributors),
    ...formatObjectiveRelation(kr.objective),
    ...formatAppraisalsRelation(loggedUser, kr.appraisals),
    ...formatUpdatesRelation(loggedUser, kr.updates),
    ...formatContributorsRelation(kr.contributors),
    ...formatOwnerRelation(kr.contributors),
  } as Partial<KeyResultFormatted>;
}

function formatId(id?: string) {
  return id ? { id } : null;
}

function formatName(name?: string) {
  return name ? { name } : null;
}

function formatDescription(description?: string) {
  return description ? { description } : null;
}

function formatStatus(status?: KeyResult['status']) {
  return status ? { status } : null;
}

function formatDeadline(deadline?: string) {
  return deadline ? { deadline: parseISO(deadline.slice(0, 10)) } : null;
}

function formatLastUpdate(lastUpdate?: string | Date) {
  return lastUpdate ? { lastUpdate: new Date(lastUpdate) } : null;
}

function formatFiscalYear(fiscalYear?: string) {
  return fiscalYear ? { fiscalYear } : null;
}

function formatFocusArea(focusArea?: FocusArea) {
  return focusArea ? { focusArea: focusArea.toLowerCase() as FocusArea } : null;
}

function formatWeightMultiplier(weightMultiplier?: number) {
  return weightMultiplier ? { weightMultiplier } : null;
}

function formatRankingIndex(rankingIndex?: number) {
  return rankingIndex ? { rankingIndex } : null;
}

function formatIsClosed(isClosed?: boolean) {
  return isNotDefined(isClosed) ? null : { isClosed };
}

function formatAgreements(contributors?: KeyResultWithRelations['contributors']) {
  if (!contributors) return null;

  const agreements = contributors.reduce(function (prev, curr) {
    if (!curr.user_id) return prev;

    return {
      ...prev,
      [curr.user_id]:
        isNotDefined(curr.agreement) || curr.agreement === 'no-action'
          ? undefined
          : curr.agreement === 'agree',
    };
  }, {} as Record<string, boolean | undefined>);

  return { agreements };
}

function formatObjectiveRelation(objective?: Partial<Objective>) {
  return objective ? { objective: formatObjective(objective) } : null;
}

function formatAppraisalsRelation(
  user: UserEmployee,
  appraisals?: KeyResultWithRelations['appraisals']
) {
  return appraisals ? { appraisals: formatKeyResultUpdates(user, appraisals) } : null;
}

function formatUpdatesRelation(user: UserEmployee, updates?: KeyResultWithRelations['updates']) {
  return updates ? { updates: formatKeyResultUpdates(user, updates) } : null;
}

function formatContributorsRelation(contributors?: KeyResultWithRelations['contributors']) {
  return contributors ? { contributors: formatContributors(contributors) } : null;
}

function formatOwnerRelation(contributors?: KeyResultWithRelations['contributors']) {
  return contributors ? { owner: formatOwner(contributors) } : null;
}

export function showWeightMultiplierFriendly(multiplier: number) {
  return `${Math.round(multiplier * 100)}%`;
}
