import { ApolloProvider } from '@apollo/client';
import { Provider as NiceModalProvider } from '@ebay/nice-modal-react';
import { GrowthBook, GrowthBookProvider, FeaturesReady } from '@growthbook/growthbook-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';
import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import { theme } from './theme';
import { PageLoading } from '../components';
import { UserProvider } from '../context';
import { apiClient } from '../services/apiClient';

function App() {
  disableDefaultPageViewMeasurement();
  let gb = initializeGrowthBook();

  useEffect(() => {
    gb.loadFeatures({ autoRefresh: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <GrowthBookProvider growthbook={gb}>
        <FeaturesReady fallback={<PageLoading isLoading={true} />}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Suspense>
              <ApolloProvider client={apiClient}>
                <SnackbarProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <NiceModalProvider>
                      <UserProvider>
                        <RouterProvider router={router} />
                      </UserProvider>
                    </NiceModalProvider>
                  </LocalizationProvider>
                </SnackbarProvider>
              </ApolloProvider>
            </Suspense>
          </ThemeProvider>
        </FeaturesReady>
      </GrowthBookProvider>
    </GoogleOAuthProvider>
  );
}

/**
 * As mentioned on the below documentation:
 * "If you send manual pageviews without disabling pageview measurement, you may end up with
 * duplicate pageviews."
 * https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag#disable_pageview_measurement
 */
function disableDefaultPageViewMeasurement() {
  gtag?.('set', 'send_page_view', false);
}

function initializeGrowthBook() {
  return new GrowthBook({
    apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST || '',
    clientKey: process.env.REACT_APP_GROWTHBOOK_KEY || 'invalid_key',
    enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEVMODE === 'true',
  });
}

export default App;
