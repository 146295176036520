import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { List } from '@mui/material';

import { items } from './PageLayout.utils';
import PageLayoutListItem from './PageLayoutListItem';
import { useContext, userContext } from '../../context';

export interface PageLayoutListProps {
  isOpen: boolean;
  onClick: () => void;
}

function PageLayoutList(props: PageLayoutListProps) {
  const { user } = useContext(userContext);

  return (
    <List
      component="nav"
      sx={{ width: '100%', overflow: 'auto', ...(!props.isOpen && { alignItems: 'center' }) }}
    >
      {items.map((item) => (
        <PageLayoutListItem
          key={item.label}
          shouldHideName={!props.isOpen}
          onClick={props.onClick}
          user={user}
          {...item}
        />
      ))}
    </List>
  );
}

export default PageLayoutList;
