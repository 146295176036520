import { Card, CardProps } from '@mui/material';
import { ElementType } from 'react';

export type PageCardProps<C extends ElementType> = CardProps<C, { component?: C }> & {
  spacing?: keyof typeof paddings;
};

function PageCard<C extends ElementType>({ children, spacing, sx, ...props }: PageCardProps<C>) {
  return (
    <Card
      data-testid="page-card"
      {...props}
      variant="outlined"
      sx={{
        ...paddings[spacing ?? 'normal'],
        borderColor: 'border.default',
        borderRadius: ({ spacing }) => spacing(1),
        textDecorationLine: 'none',
        ...sx,
      }}
    >
      {children}
    </Card>
  );
}

const paddings = {
  compact: { paddingX: 2, paddingY: 1.5 },
  normal: { paddingX: { xs: 4, md: 3 }, paddingY: 3 },
} as const;

export default PageCard;
