import { KeyResultCardProps } from './KeyResultCard.types';
import KeyResultCardDetails from './Variations/KeyResultCardDetails';
import KeyResultCardEmbedded from './Variations/KeyResultCardEmbedded';
import KeyResultCardTable from './Variations/KeyResultCardTable';

function KeyResultCard(props: KeyResultCardProps) {
  switch (props.variant) {
    case 'details':
      return <KeyResultCardDetails {...props} />;
    case 'table':
      return <KeyResultCardTable {...props} />;
    case 'embedded':
      return <KeyResultCardEmbedded {...props} />;
  }
}

export default KeyResultCard;
