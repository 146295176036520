import { QueryHookOptions, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import {
  KeyResultFormattedResponse,
  KeyResultWithRelations,
  formatKeyResult,
} from './mappers/keyResult.mapper';
import { QueryResponse, buildQuery } from './service.utils';
import { useContext, userContext } from '../context';
import { BooleanObject, RemoveFalse } from '../utils';

export function useGetKeyResults<F extends BooleanObject<KeyResultWithRelations>>(
  name: string,
  fields: F,
  options?: QueryHookOptions<Data<F>, Variables>
) {
  const { enqueueSnackbar } = useSnackbar();
  const { user: loggedUser } = useContext(userContext);

  return useQuery<Data<F>, Variables>(
    buildQuery({
      name,
      fields,
      query: 'getKeyResults',
      filters: [
        ['ids', '[String!]', 'ids'],
        ['userId', 'String', 'userId'],
        ['filter', 'KeyResultFilter', 'filter'],
      ],
    }),
    {
      ...options,
      onError(error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted(data) {
        data.formatted = (data.getKeyResults || []).map((keyResult) =>
          formatKeyResult(loggedUser!, keyResult)
        ) as Data<F>['formatted'];

        options?.onCompleted?.(data);
      },
    }
  );
}

export interface Data<F = any> {
  getKeyResults: Array<QueryResponse<F, KeyResultWithRelations>>;
  formatted: Array<KeyResultFormattedResponse<RemoveFalse<F>>>;
}

interface Variables {
  ids?: string[];
  userId?: string;
  filter?: {
    name?: string;
    fiscal_year?: string;
  };
}
