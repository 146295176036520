import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { API_URI } from './apiClient';
import { downloadFile } from '../utils';

export function useUploadEmployees() {
  const { enqueueSnackbar } = useSnackbar();
  const [request, setRequest] = useState({ data: null as any, error: null, loading: false });

  const callAPI = useCallback(
    function (file: File) {
      const headers = new Headers({
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')!)}`,
        'apollo-require-preflight': 'true',
      });

      const formData = new FormData();
      formData.append('operations', buildOperations());
      formData.append('map', JSON.stringify({ '0': ['variables', 'file'] }));
      formData.append('0', file, file.name);

      const requestOptions = {
        method: 'POST',
        headers,
        body: formData,
      };

      setRequest((currentState) => ({ ...currentState, loading: true }));

      fetch(API_URI!, requestOptions)
        .then((response) => response.json())
        .then(({ data, errors }) => {
          if (errors) {
            return Promise.reject(errors[0] ?? new Error('Unexpected error'));
          }

          setRequest({ loading: false, data: data.employeeUpload, error: null });
          enqueueSnackbar(data.employeeUpload.message, { variant: 'success' });
          downloadFile(data.employeeUpload.issues, { fileName: 'employees-issues' });
        })
        .catch((error) => {
          setRequest({ loading: false, data: null, error });
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  return [callAPI, request] as const;
}

function buildOperations() {
  const variables = { file: null };
  const query =
    'mutation UploadEmployees($file: Upload!) {\n' +
    'employeeUpload(file: $file) {\n message\n success\n issues\n }\n}';

  return JSON.stringify({ query, variables });
}
