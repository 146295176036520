import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

export interface ArchiveModalProps extends DialogProps {
  onAgree: () => void;
  onCancel: () => void;
  textArchived: string;
}

function ArchiveKrModal({ onAgree, onCancel, ...props }: ArchiveModalProps) {
  const arquiveText = props.textArchived === 'archive' ? 'Archive' : 'Unarchive';
  return (
    <Dialog data-testid="archive-modal" {...props}>
      <DialogTitle> {arquiveText} KR</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {props.textArchived} this KR?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="success" onClick={onAgree}>
          {arquiveText}
        </Button>

        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ArchiveKrModal;
