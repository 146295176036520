import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { User } from '../utils';

interface GetUserByIDResult {
  getUserByID: User;
}

export function useGetUserById(id: String) {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery<GetUserByIDResult>(getUserByIDQuery, {
    variables: { getUserByIdId: id },
    fetchPolicy: 'no-cache',
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return result;
}

export const getUserByIDQuery = gql`
  query ($getUserByIdId: String!) {
    getUserByID(id: $getUserByIdId) {
      name
      id
      email
      roles
      is_active
      profile_picture
    }
  }
`;
