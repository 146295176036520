import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

interface CreateKeyResultCommentResult {
  createKeyResultComment: { id: string };
}

export function useCreateKeyResultComment() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useMutation<CreateKeyResultCommentResult>(createKeyResultCommentMutation, {
    variables: { type: 'comment' },
    onCompleted: function () {
      enqueueSnackbar('comment created successfully', { variant: 'success' });
    },
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return result;
}

export const createKeyResultCommentMutation = gql`
  mutation CreateComment($krId: String!, $type: String!, $payload: KeyResultComment!) {
    createKeyResultComment(kr_id: $krId, type: $type, payload: $payload) {
      id
    }
  }
`;
