import {
  EmployeeFormatted,
  EmployeeFormattedToMapper,
  EmployeeMappedFields,
  formatEmployee,
} from './employee.mapper';
import { Contributor, Employee, isNotDefined } from '../../utils';
import { FormattedResponse } from '../service.utils';

export type ContributorFormattedResponse<BObj> = FormattedResponse<
  ContributorFormattedToMapper,
  BObj,
  ContributorMappedFields
>;

export interface ContributorFormattedToMapper extends ContributorCommon {
  employee: [EmployeeFormattedToMapper, EmployeeMappedFields];
}

export interface ContributorFormatted extends ContributorCommon {
  employee?: Partial<EmployeeFormatted>;
}

export interface ContributorCommon {
  id: Contributor['user_id'];
  name: Contributor['name'];
  weight: Contributor['user_kr_weight'];
  weight_real: Contributor['user_kr_weight_real'];
  type: Contributor['user_type'];
  isActive: Contributor['is_active'];
}

export interface ContributorMappedFields {
  id: 'user_id';
  weight: 'user_kr_weight';
  weight_real: 'user_kr_weight_real';
  type: 'user_type';
  isActive: 'is_active';
}

export interface ContributorWithRelations extends Contributor {
  employee?: Partial<Employee>;
}

export function formatOwner(contributors: Array<Partial<ContributorWithRelations>> = []) {
  const owner = contributors.find(({ user_type }) => user_type?.toLocaleLowerCase() === 'owner');

  return owner ? formatContributor(owner) : undefined;
}

export function formatContributors(contributors: Array<Partial<ContributorWithRelations>> = []) {
  return contributors.map(formatContributor);
}

export function formatContributor(contributor: Partial<ContributorWithRelations> = {}) {
  return {
    ...formatId(contributor.user_id),
    ...formatName(contributor.name),
    ...formatWeight(contributor.user_kr_weight),
    ...formatWeightReal(contributor.user_kr_weight_real),
    ...formatType(contributor.user_type),
    ...formatIsActive(contributor.is_active),
    ...formatEmployeeRelation(contributor.employee),
  } as Partial<ContributorFormatted>;
}

function formatId(id?: Contributor['user_id']) {
  return id ? { id } : null;
}

function formatName(name?: Contributor['name']) {
  return name ? { name } : null;
}

function formatWeight(weight?: Contributor['user_kr_weight']) {
  return weight ? { weight } : null;
}

function formatWeightReal(weight_real?: Contributor['user_kr_weight_real']) {
  return { weight_real: weight_real ?? 0 };
}

function formatType(type?: Contributor['user_type']) {
  return type ? { type } : null;
}

function formatIsActive(isActive?: Contributor['is_active']) {
  return isNotDefined(isActive) ? null : { isActive };
}

function formatEmployeeRelation(employee?: ContributorWithRelations['employee']) {
  return employee ? { employee: formatEmployee(employee) } : null;
}
