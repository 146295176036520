export enum Route {
  HOME = '/',
  KRS = '/key-results',
  KRS_DETAILS = '/key-results/:id',
  CREATE_KR = '/key-results/create',
  EDIT_KR = '/key-results/:id/edit',
  LOGIN = '/login',
  LOGOUT = '/logout',
  LIST_USERS = '/users',
  CREATE_USER = '/users/new',
  EDIT_USER = '/users/:id',
  CHANGE_PASSWORD = '/users/change-password',
  FORCE_CHANGE_PASSWORD = '/users/force-change-password',
  CHANGE_PASSWORD_AS_ADMIN = '/users/:id/change-password',
  LIST_USERS_PAGE = '/users?page=:page&name=:name',
  LIST_OBJECTIVES = '/objectives',
  OBJECTIVES_DETAILS = '/objectives/:id',
  CREATE_OBJECTIVE = '/objectives/new',
  EDIT_OBJECTIVE = '/objectives/:id/edit',
  CREATE_APPRAISAL = '/key-results/:id/appraisal',
  LIST_EMPLOYEES = '/employees',
  DETAIL_EMPLOYEE = '/employees/:id',
  ORG_CHART = '/org-chart',
  MANAGE_APPRAISALS = '/appraisals',
  MANAGE_APPRAISALS_MY = '/appraisals/my',
  MANAGE_APPRAISALS_TEAM = '/appraisals/team',
  MANAGE_APPRAISALS_ALL = '/appraisals/all',
}
