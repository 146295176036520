import { Stack, StackProps, TooltipProps, Typography, TypographyProps } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

import HelpTooltip from '../HelpTooltip/HelpTooltip';

export interface DefinitionProps {
  direction: 'row' | 'column';
  label: string;
  tooltipText?: TooltipProps['title'];
  value: ReactNode;
  labelFontSize?: string;
  valueFontSize?: string;
  valueAlign?: CSSProperties['alignItems'];
  valueJustify?: CSSProperties['justifyContent'];
  valueNoWrap?: TypographyProps['noWrap'];
  sx?: StackProps['sx'];
}

function Definition(props: DefinitionProps) {
  return (
    <Stack
      direction={props.direction}
      alignItems={props.valueAlign || 'center'}
      justifyContent={props.valueJustify || 'center'}
      spacing={1}
      sx={props.sx}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        textAlign={props.direction === 'row' ? 'left' : 'center'}
        textTransform="capitalize"
        fontSize={props.labelFontSize}
      >
        {props.label}:
      </Typography>

      {['string', 'number'].includes(typeof props.value) ? (
        <Typography
          variant="body2"
          color="text.primary"
          fontWeight="bold"
          textAlign="left"
          fontSize={props.valueFontSize}
          noWrap={props.valueNoWrap}
        >
          {props.value}
        </Typography>
      ) : (
        props.value
      )}

      {Boolean(props.tooltipText) && (
        <HelpTooltip title={props.tooltipText} fontSize="0.9rem" alignSelf="flex-start" />
      )}
    </Stack>
  );
}

export default Definition;
