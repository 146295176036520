import { gql, useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { ContributorFormatted, formatContributor } from './mappers/contributor.mapper';
import { RawContributor, formatAgreements } from './mappers/keyResultAgreements.mapper';
import { FocusArea, KeyResult, Objective } from '../utils';

interface GetKeyResultById {
  getKeyResults: Array<KeyResult & { objective: Objective; contributors: RawContributor[] }>;
}

export function useGetKeyResultById(ids: [string]) {
  const { enqueueSnackbar } = useSnackbar();

  const { data, ...result } = useQuery<GetKeyResultById>(getKeyResultByIdQuery, {
    variables: { ids },
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const mappedData = useMemo(
    function () {
      if (!data || !data.getKeyResults) {
        return [];
      }

      return data.getKeyResults.map(function (kr) {
        const contributors = kr.contributors.map(formatContributor) as ContributorFormatted[];

        return {
          id: kr.id,
          objective: {
            ...kr.objective,
          },
          name: kr.name,
          description: kr.how_to_calculate_metric,
          status: kr.status,
          deadline: parseISO(kr.deadline.slice(0, 10)),
          lastUpdate: new Date(kr.updatedAt),
          owner: contributors.find(({ type }) => type.toLocaleLowerCase() === 'owner')!,
          contributors,
          focusArea: kr.focus_area?.toLowerCase() as FocusArea,
          fiscalYear: kr.fiscal_year,
          weight_multiplier: kr.weight_multiplier,
          rankingIndex: kr.ranking_index,
          agreements: formatAgreements(kr.contributors),
          isClosed: kr.is_closed,
          archivedAt: kr.archived_at,
        };
      });
    },
    [data]
  );

  return { data: { getKeyResults: mappedData }, ...result };
}

export const getKeyResultByIdQuery = gql`
  query GetKeyResultById($ids: [String!]) {
    getKeyResults(ids: $ids) {
      id
      name
      how_to_calculate_metric
      status
      deadline
      archived_at
      updatedAt
      focus_area
      fiscal_year
      weight_multiplier
      ranking_index
      is_closed
      objective {
        id
        name
        description
      }
      contributors {
        user_type
        user_kr_weight
        user_kr_weight_real
        agreement
        user_id
        name
        is_active
        employee_id
        employee {
          id
          preferredName
          profilePicture
        }
      }
    }
  }
`;
