import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

interface CreateKeyResultProgressResult {
  createKeyResultProgressUpdate: { id: string };
}

export function useCreateKeyResultProgress() {
  const { enqueueSnackbar } = useSnackbar();

  const result = useMutation<CreateKeyResultProgressResult>(createKeyResultProgressMutation, {
    variables: { type: 'progress' },
    onCompleted: function () {
      enqueueSnackbar('progress updated successfully', { variant: 'success' });
    },
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return result;
}

export const createKeyResultProgressMutation = gql`
  mutation CreateProgress($krId: String!, $type: String!, $payload: KeyResultProgress!) {
    createKeyResultProgressUpdate(kr_id: $krId, type: $type, payload: $payload) {
      id
    }
  }
`;
