import { Stack, StackProps } from '@mui/material';

import { mainPaddingVertical } from '../PageLayout/PageLayout';

interface FilterProps extends StackProps {
  shouldHighlight?: boolean;
}

function Filter({ shouldHighlight, sx, ...props }: FilterProps) {
  return (
    <Stack
      data-testid="filter"
      direction="row"
      spacing={5}
      {...props}
      sx={{
        position: 'absolute',
        top: ({ spacing }) => spacing(-mainPaddingVertical),
        left: 0,
        right: 0,
        backgroundColor: 'background.accent',
        '&:before': {
          content: '""',
          width: '50vw',
          backgroundColor: shouldHighlight ? 'background.accentHighlight' : 'background.accent',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: '100%',
        },
        '&:after': {
          content: '""',
          width: '50vw',
          backgroundColor: 'background.accent',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '100%',
        },
        '& > :first-of-type': {
          paddingRight: 5,
        },
        ...sx,
      }}
    />
  );
}

export default Filter;
export const spacingFromFilter = mainPaddingVertical + 4;
export { FilterFieldWrapper, FilterHighlightedFieldWrapper } from './Filter.components';
