import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { Employee } from '../utils/types/employee.types';

interface GetEmployeeByIdResult {
  getEmployeeById: Employee;
}

export function useGetEmployeeById(id: String) {
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery<GetEmployeeByIdResult>(getEmployeeByIdQuery, {
    variables: { id: id },
    fetchPolicy: 'no-cache',
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return result;
}

export const getEmployeeByIdQuery = gql`
  query ($id: String!) {
    getEmployeeById(id: $id) {
      id
      name
      email
      reports_to
      position
      registration_id
      user_id
      business_unit
      country
      ceo_level
      department
      seniority
      employed_at
      endDate
      cecoDescription
      preferredName
      directManager {
        preferredName
      }
    }
  }
`;
