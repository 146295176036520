import { Box, BoxProps, Chip } from '@mui/material';

import PageCard from '../../PageCard/PageCard';

export interface KeyResultCardHighlightedProps extends BoxProps {
  highlightCard?: boolean;
  agreement?: boolean;
}

function KeyResultCardHighlighted({
  children,
  highlightCard,
  agreement,
  ...props
}: KeyResultCardHighlightedProps) {
  return (
    <Box position="relative" {...props}>
      {highlightCard && (
        <Chip
          label="★"
          size="small"
          sx={{
            color: 'common.white',
            bgcolor: ({ palette }) => palette.border.highlight,
            position: 'absolute',
            top: 0,
            left: ({ spacing }) => ({ xs: spacing(4), md: spacing(3) }),
            transform: 'translateY(-50%)',
          }}
        />
      )}

      <PageCard
        sx={{
          ...(highlightCard && { borderColor: 'border.highlight' }),
          ...(agreement !== undefined && {
            borderLeftWidth: '5px',
            borderLeftColor: agreement ? 'success.main' : 'error.light',
          }),
        }}
      >
        {children}
      </PageCard>
    </Box>
  );
}

export default KeyResultCardHighlighted;
