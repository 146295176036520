import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { NodeData } from '../vendor/dia-org-chart/types';

export function useGetOrgChart(options?: QueryHookOptions<Data, Variables>) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<Data, Variables>(getOrgChartQuery, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
}

interface Data {
  getOrgChartHierarchy: OrgChartHierarchy;
}

export interface OrgChartHierarchy extends NodeData {
  id: string;
  employeeId?: string;
  ceo_level?: string;
  name?: string;
  position?: string;
  seniority?: string;
  parent_id?: string;
  profile_picture?: string;
  business_unit?: string;
  children: OrgChartHierarchy[];
}

interface Variables {}

export const getOrgChartQuery = gql`
  query GetOrgChart {
    getOrgChartHierarchy {
      id
      employeeId
      seniority
      ceo_level
      profile_picture
      name
      position
      business_unit
      children
    }
  }
`;
