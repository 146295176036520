import { Box, styled } from '@mui/material';

export const FilterFieldWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const FilterHighlightedFieldWrapper = styled(FilterFieldWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.background.accentHighlight,
}));
