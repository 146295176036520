import { Avatar, Stack, Tooltip, Typography } from '@mui/material';

import { useContext, userContext } from '../../context';
import { cropGoogleImage } from '../../utils';

export interface PageLayoutProfileProps {
  isOpen: boolean;
}

function PageLayoutProfile(props: PageLayoutProfileProps) {
  const { user } = useContext(userContext);
  const profileSize = props.isOpen ? 5 : 3;
  const userName = user?.employee.preferredName;
  const userPicture = user?.employee.profilePicture;

  return (
    <Stack alignItems="center" spacing={0.5}>
      <Tooltip
        title={`If you want to change your profile picture, you have to change in your Google account`}
      >
        <Avatar
          src={cropGoogleImage(70, userPicture ?? '')}
          data-testid="contributor-item"
          alt={userName}
          data-highlight="true"
          sx={{
            width: (theme) => theme.typography.fontSize * profileSize,
            height: (theme) => theme.typography.fontSize * profileSize,
          }}
        ></Avatar>
      </Tooltip>

      {props.isOpen && (
        <Typography
          sx={{
            fontSize: '1.25rem',
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            textAlign: 'center',
            letterSpacing: '0.25px',
          }}
        >
          {userName}
        </Typography>
      )}
    </Stack>
  );
}

export default PageLayoutProfile;
