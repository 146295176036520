import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

interface CreateKeyResultAppraisalResult {
  createKeyResultAppraisal: { id: string };
}

type CreateKeyResultAppraisalOptions = MutationHookOptions<CreateKeyResultAppraisalResult>;

export function useCreateKeyResultAppraisal(options?: CreateKeyResultAppraisalOptions) {
  const { enqueueSnackbar } = useSnackbar();

  const result = useMutation<CreateKeyResultAppraisalResult>(createKeyResultAppraisalMutation, {
    ...options,
    variables: { type: 'appraisal' },
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return result;
}

export const createKeyResultAppraisalMutation = gql`
  mutation CreateAppraisal($krId: String!, $type: String!, $payload: KeyResultAppraisal!) {
    createKeyResultAppraisal(kr_id: $krId, type: $type, payload: $payload) {
      id
    }
  }
`;
