interface DownloadFileOptions {
  fileName: string;
}

export function downloadFile(base64: string, options: DownloadFileOptions) {
  const source = `data:text/csv;base64,${base64}`;
  const fileName = `${new Date().toISOString().slice(0, 10)}-${options.fileName}.csv`;

  const element = document.createElement('a');
  element.href = source;
  element.download = fileName;
  element.click();
}
