import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

export interface UpdateKeyResultResult {
  createKeyResult: { id: string };
}

export function useUpdateKeyResult(options?: MutationHookOptions) {
  const { enqueueSnackbar } = useSnackbar();

  const result = useMutation<UpdateKeyResultResult>(updateKeyResultMutation, {
    onError: function (error) {
      console.log('Error!', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    ...options,
  });

  return result;
}

export const updateKeyResultMutation = gql`
  mutation UpdateKeyResult(
    $id: String!
    $objective_id: String!
    $name: String!
    $how_to_calculate_metric: String!
    $status: String!
    $focus_area: String!
    $deadline: Date!
    $contributors: [KeyResultContributor!]!
    $weight_multiplier: Float!
    $ranking_index: Int
    $archived_at: Date
    $is_closed: Boolean
  ) {
    updateKeyResult(
      id: $id
      objective_id: $objective_id
      name: $name
      how_to_calculate_metric: $how_to_calculate_metric
      status: $status
      focus_area: $focus_area
      deadline: $deadline
      contributors: $contributors
      weight_multiplier: $weight_multiplier
      ranking_index: $ranking_index
      archived_at: $archived_at
      is_closed: $is_closed
    ) {
      id
    }
  }
`;
