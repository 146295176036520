import HelpIcon from '@mui/icons-material/Help';
import { SvgIconProps, Tooltip, TooltipProps } from '@mui/material';
import { CSSProperties } from 'react';

export interface HelpTooltipProps {
  title: TooltipProps['title'];
  fontSize?: CSSProperties['fontSize'];
  alignSelf?: CSSProperties['alignSelf'];
  justifySelf?: CSSProperties['justifySelf'];
  tooltipProps?: Partial<TooltipProps>;
  iconProps?: Partial<SvgIconProps>;
}

function HelpTooltip(props: HelpTooltipProps) {
  return (
    <Tooltip title={props.title} arrow {...props.tooltipProps}>
      <HelpIcon
        color="action"
        fontSize="small"
        sx={{
          fontSize: props.fontSize,
          alignSelf: props.alignSelf,
          justifySelf: props.justifySelf,
        }}
        {...props.iconProps}
      />
    </Tooltip>
  );
}

export default HelpTooltip;
