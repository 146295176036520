import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { UserEmployee } from '../utils';

interface GetCurrentUserResult {
  getCurrentUser: UserEmployee;
}

export function useGetCurrentUser(page?: number) {
  const { enqueueSnackbar } = useSnackbar();
  const options: QueryHookOptions = {
    fetchPolicy: 'no-cache',
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: function (data) {
      const rawUser = data.getCurrentUser;
      data.getCurrentUser = {
        id: rawUser.id,
        name: rawUser.name,
        email: rawUser.email,
        forcePasswordChangeFlag: rawUser.force_password_change_flag,
        roles: rawUser.roles,
        reports_to: rawUser.reports_to,
        employee: {
          ...rawUser.employee,
          id: rawUser.employee!.id,
          preferredName: rawUser.employee!.preferredName!,
        },
      };
    },
  };
  return useQuery<GetCurrentUserResult>(getCurrentUserQuery, options);
}

export const getCurrentUserQuery = gql`
  query {
    getCurrentUser {
      id
      email
      name
      force_password_change_flag
      roles
      reports_to
      employee {
        id
        preferredName
        profilePicture
      }
    }
  }
`;
