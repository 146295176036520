import { Box, BoxProps, Button, Stack, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { addYears, startOfMonth, endOfYear, parseISO, addDays } from 'date-fns';
import { FormikConfig, useFormik } from 'formik';

export interface ObjectiveFormProps extends Omit<BoxProps<'form'>, 'onSubmit'> {
  initialValues?: Partial<ObjectiveFormValues>;
  onSubmit: FormikConfig<ObjectiveFormValues>['onSubmit'];
}

interface ObjectiveFormValues {
  name: string;
  description: string;
  deadline: string | null;
}

function ObjectiveForm({ initialValues, onSubmit, ...props }: ObjectiveFormProps) {
  const form = useFormik({
    initialValues: {
      name: initialValues?.name ?? '',
      description: initialValues?.description ?? '',
      deadline: initialValues?.deadline ?? null,
    },
    onSubmit,
  });

  const isNameEmpty = !form.values.name;
  const isDescriptionEmpty = !form.values.description;
  const isDeadlineEmpty = !form.values.deadline;
  const isLoading = form.isSubmitting;
  const shouldDisableButton = isNameEmpty || isDescriptionEmpty || isDeadlineEmpty || isLoading;
  const maxDate = endOfYear(addYears(new Date(), 1));

  return (
    <Box {...props} component="form" onSubmit={form.handleSubmit}>
      <Stack spacing={2}>
        <TextField
          name="name"
          label="Name"
          onChange={form.handleChange}
          value={form.values.name}
          fullWidth
        />

        <TextField
          name="description"
          label="Description"
          onChange={form.handleChange}
          value={form.values.description}
          maxRows={4}
          multiline
          fullWidth
        />

        <MobileDatePicker
          disablePast
          label="Deadline"
          inputFormat="MM/yyyy"
          minDate={parseISO('2022-01-01')}
          maxDate={maxDate}
          value={form.values.deadline}
          onChange={(value) => form.setFieldValue('deadline', addDays(startOfMonth(value!), 1))}
          renderInput={(params) => <TextField {...params} />}
          DialogProps={{ 'data-testid': 'date-picker', fullWidth: false } as any}
          openTo="month"
          views={['month', 'year']}
        />

        <Button type="submit" size="large" variant="contained" disabled={shouldDisableButton}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default ObjectiveForm;
