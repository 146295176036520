import ProgressIcon from '@mui/icons-material/AnalyticsOutlined';
import BlockIcon from '@mui/icons-material/Block';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AppraisalIcon from '@mui/icons-material/DescriptionOutlined';
import QuestionIcon from '@mui/icons-material/QuestionMarkOutlined';
import CommentIcon from '@mui/icons-material/SmsOutlined';
import { format as formatDate, parseISO } from 'date-fns';
import { ElementType, ReactNode } from 'react';

import {
  EmployeeFormatted,
  EmployeeFormattedToMapper,
  EmployeeMappedFields,
  EmployeeWithRelations,
  formatEmployee,
} from './employee.mapper';
import { KeyResultWithRelations, showWeightMultiplierFriendly } from './keyResult.mapper';
import { KeyResultStatus } from '../../components';
import { KeyResultUpdate, User, UserEmployee, isNotDefined } from '../../utils';
import { FormattedResponse } from '../service.utils';

export type KeyResultUpdateFormattedResponse<BObj> = FormattedResponse<
  KeyResultUpdateFormattedToMapper,
  BObj,
  KeyResultUpdateMappedFields
>;

export interface KeyResultUpdateFormattedToMapper extends KeyResultUpdateCommon {
  employee: [EmployeeFormattedToMapper, EmployeeMappedFields];
  directManager: [EmployeeFormattedToMapper, EmployeeMappedFields];
  approver: [EmployeeFormattedToMapper, EmployeeMappedFields];
}

export interface KeyResultUpdateFormatted extends KeyResultUpdateCommon {
  employee?: Partial<EmployeeFormatted>;
  directManager?: Partial<EmployeeFormatted>;
  approver?: Partial<EmployeeFormatted>;
}

interface KeyResultUpdateCommon {
  id: KeyResultUpdate['id'];
  type: KeyResultUpdate['type'];
  payload: KeyResultUpdate['payload'];
  updatedAt: string;
  updater: string;
  updaterId: string;
  isUpdaterActive: boolean;
  likesCount: number;
  dislikesCount: number;
  isLiked: boolean;
  icon: ElementType;
  action: string;
  status: string;
  description: ReactNode;
  createdAt: string;
  details: Array<{
    key: string;
    value: ReactNode;
  }>;
}

export interface KeyResultUpdateMappedFields {
  updatedAt: 'updatedAt';
  updater: 'user.name';
  updaterId: 'user.id';
  isUpdaterActive: 'user.is_active';
  likesCount: 'payload';
  dislikesCount: 'payload';
  isLiked: 'payload';
  icon: 'type' | 'payload';
  action: 'type' | 'payload';
  description: 'type' | 'payload';
  details: 'type' | 'payload' | 'directManager.name';
}

export interface KeyResultUpdateWithRelations extends KeyResultUpdate {
  user?: User;
  keyResult?: Partial<KeyResultWithRelations>;
  employee?: Partial<EmployeeWithRelations>;
  directManager?: Partial<EmployeeWithRelations>;
  approver?: Partial<EmployeeWithRelations>;
}

export function formatKeyResultUpdates(
  loggedUser: UserEmployee,
  updates: Partial<KeyResultUpdateWithRelations>[] = []
) {
  return enrichAppraisalApproval(updates)
    .filter(isNotApproval)
    .map((update) => ({
      ...formatId(update.id),
      ...formatType(update.type),
      ...formatPayload(update.payload),
      ...formatUpdatedAt(update.updatedAt),
      ...formatCreatedAt(update.createdAt),
      ...formatUpdater(update.user?.employee?.preferredName ?? update.user?.name),
      ...formatUpdaterId(update.user?.id),
      ...formatIsUpdaterActive(update.user?.is_active),
      ...formatLikesAndDislikes(loggedUser.id, update.payload?.likes || {}),
      ...formatPresentationDetails(update),
      ...formatEmployeeRelation(update.employee),
      ...formatDirectManagerRelation(update.directManager),
      ...formatApproverRelation(update.approver),
      ...formatStatus(update.status),
    })) as Array<Partial<KeyResultUpdateFormatted>>;
}

function enrichAppraisalApproval(updates: Partial<KeyResultUpdateWithRelations>[]) {
  return updates.map((update) => {
    if (update.type === 'appraisal_approval') {
      const appraisalByApproval = updates.find((item) => item.id === update.payload?.appraisal_id);
      return {
        ...update,
        payload: { ...appraisalByApproval?.payload, ...update.payload },
      };
    }
    return update;
  });
}

function isNotApproval(update: Partial<KeyResultUpdateWithRelations>) {
  if (!update.type) {
    return false;
  }

  return update.type !== 'like';
}

function formatId(id?: KeyResultUpdate['id']) {
  return id ? { id } : null;
}

function formatType(type?: KeyResultUpdate['type']) {
  return type ? { type } : null;
}

function formatPayload(payload?: KeyResultUpdate['payload']) {
  return payload ? { payload } : null;
}

function formatUpdatedAt(updatedAt?: KeyResultUpdate['createdAt']) {
  return updatedAt ? { updatedAt: formatDate(parseISO(updatedAt), 'dd/MM/yyyy HH:mm z') } : null;
}

function formatCreatedAt(createdAt?: KeyResultUpdate['createdAt']) {
  return createdAt ? { createdAt: formatDate(parseISO(createdAt), 'dd/MM/yyyy HH:mm z') } : null;
}

function formatUpdater(updater?: string) {
  return updater ? { updater } : null;
}

function formatStatus(status?: KeyResultUpdate['status']) {
  return status ? { status } : null;
}

function formatUpdaterId(updaterId?: string) {
  return updaterId ? { updaterId } : null;
}

function formatIsUpdaterActive(isUpdaterActive?: boolean) {
  return isNotDefined(isUpdaterActive) ? null : { isUpdaterActive };
}

function formatLikesAndDislikes(loggedUserId: string, data: Record<string, boolean>) {
  const entries = Object.entries(data);
  const likesCount = entries.filter(([_, isLiked]) => isLiked).length;
  const dislikesCount = entries.filter(([_, isLiked]) => !isLiked).length;
  const isLiked = entries.find(([userId]) => userId === loggedUserId)?.[1];

  return { likesCount, dislikesCount, isLiked };
}

function formatPresentationDetails(update: Partial<KeyResultUpdateWithRelations>) {
  const payload = update.payload ?? {};
  const type = update.type ?? 'unknown';

  switch (type) {
    case 'appraisal':
      return {
        icon: AppraisalIcon,
        iconColor: '#00A9E0',
        action: 'added an appraisal',
        description: payload.action_plan || payload.strengths,
        details: [
          { key: 'Direct manager', value: update.directManager?.preferredName || emptyValue },
          { key: 'Period', value: payload.period },
          { key: 'Result to date', value: payload.strengths || emptyValue },
          { key: 'Opportunities', value: payload.weaknesses || emptyValue },
          { key: 'Mitigation/action plan', value: payload.action_plan || emptyValue },
          {
            key: 'Deadline for the plan',
            value: payload.deadline
              ? formatDate(parseISO(payload.deadline.slice(0, 10)), 'dd/MM/yyyy')
              : emptyValue,
          },
        ],
      };
    case 'progress':
      return {
        icon: ProgressIcon,
        iconColor: '#9B1889',
        action: 'updated the progress',
        description: (
          <>
            Updated the status from <KeyResultStatus status={payload.old_status} /> to{' '}
            <KeyResultStatus status={payload.new_status} />.
          </>
        ),
        details: [
          { key: 'Description', value: payload.remarks },
          {
            key: 'Weight multiplier',
            value:
              typeof payload.weight_multiplier === 'number'
                ? showWeightMultiplierFriendly(payload.weight_multiplier)
                : emptyValue,
          },
        ],
      };
    case 'comment':
      return {
        icon: CommentIcon,
        iconColor: '#E98300',
        action: 'wrote a comment',
        description: payload.comment,
        details: [],
      };
    case 'appraisal_approval':
      return {
        icon: payload.is_approved ? CheckOutlinedIcon : BlockIcon,
        iconColor: payload.is_approved ? '#69BE28' : '#A6281F',
        action: payload.is_approved ? 'approved an appraisal' : 'disapproved an appraisal',
        description: payload.comment || emptyValue,
        details: [
          { key: 'Direct manager', value: update.directManager?.preferredName || emptyValue },
          { key: 'Period', value: payload.period },
          { key: 'Result to date', value: payload.strengths || emptyValue },
          { key: 'Opportunities', value: payload.weaknesses || emptyValue },
          { key: 'Mitigation/action plan', value: payload.action_plan || emptyValue },
          {
            key: 'Deadline for the plan',
            value: payload.deadline
              ? formatDate(parseISO(payload.deadline.slice(0, 10)), 'dd/MM/yyyy')
              : emptyValue,
          },
        ],
      };
    default:
      return {
        icon: QuestionIcon,
        action: 'unknown action',
        description: emptyValue,
        details: [],
      };
  }
}

function formatEmployeeRelation(employee?: Partial<EmployeeWithRelations>) {
  return employee ? { employee: formatEmployee(employee) } : null;
}

function formatDirectManagerRelation(directManager?: Partial<EmployeeWithRelations>) {
  return directManager ? { directManager: formatEmployee(directManager) } : null;
}

function formatApproverRelation(approver?: Partial<EmployeeWithRelations>) {
  return approver ? { approver: formatEmployee(approver) } : null;
}

const emptyValue = 'N/A';
