import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

interface GetKeyResultsNameResult {
  getKeyResults: Array<{
    id: string;
    name: string;
  }>;
}

export function useGetKeyResultsName() {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<GetKeyResultsNameResult>(getKeyResultsNameQuery, {
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
}

export const getKeyResultsNameQuery = gql`
  query GetKeyResultsName {
    getKeyResults {
      id
      name
    }
  }
`;
