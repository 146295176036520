import { useCallback, useState } from 'react';

import { KeyResultCardListVariants } from '../../components';
import { LocalStorageKeys } from '../../utils/constants/localStorageKey';

interface LocalStorageValues {
  [LocalStorageKeys.TOKEN]: string | null;
  [LocalStorageKeys.KEY_RESULT_VIEW_MODE]: KeyResultCardListVariants | null;
}

export function useLocalStorage<T extends LocalStorageKeys>(
  key: T,
  defaultValue?: LocalStorageValues[T]
) {
  const [storedValue, setStoredValue] = useState<LocalStorageValues[T]>(function () {
    try {
      const value = window.localStorage.getItem(key);

      return value ? JSON.parse(value) : defaultValue ?? null;
    } catch (error) {
      return defaultValue ?? null;
    }
  });

  const setValue = useCallback(
    function (value: LocalStorageValues[T]) {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
        setStoredValue(value);
      } catch (error) {
        throw error;
      }
    },
    [key]
  );

  const removeValue = useCallback(
    function () {
      window.localStorage.removeItem(key);
      setStoredValue(null);
    },
    [key]
  );

  return [storedValue, setValue, removeValue] as [
    typeof storedValue,
    typeof setValue,
    typeof removeValue
  ];
}
