import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { Employee } from '../utils/types/employee.types';

export function useGetEmployees(options?: QueryHookOptions<Employees, Variables>) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<Employees, Variables>(getEmployeesQuery, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
}
interface Employees {
  getEmployees: {
    employees: Employee[];
    count: number;
  };
}

interface Variables {
  limit?: number;
  offset?: number;
  filter?: {
    name?: string;
  };
}

export const getEmployeesQuery = gql`
  query GetEmployees($limit: Int, $offset: Int, $filter: EmployeeFilter) {
    getEmployees(limit: $limit, offset: $offset, filter: $filter) {
      count
      employees {
        id
        name
        email
        position
        user_id
        reports_to
        preferredName
        user {
          id
        }
      }
    }
  }
`;
