import BackArrow from '@mui/icons-material/KeyboardBackspace';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

interface BreadcrumbsProps {
  items?: string[];
  backLink?: {
    label: string;
    to: LinkProps['to'];
  };
  state?: any;
}

function Breadcrumbs({ backLink, items, state }: BreadcrumbsProps) {
  return (
    <MuiBreadcrumbs data-testid="breadcrumbs">
      {Boolean(backLink) && (
        <Link
          underline="none"
          fontSize="small"
          fontWeight="bold"
          component={RouterLink}
          to={backLink!.to}
          state={state}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <BackArrow sx={{ mr: 1, fontSize: '18px' }} />
          {backLink!.label}
        </Link>
      )}

      {items?.map((item) => (
        <Typography key={item} fontSize="small" fontWeight="bold">
          {item}
        </Typography>
      ))}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
