import ArchiveIcon from '@mui/icons-material/Archive';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { HasRole } from '../../index';
import KeyResultCardAgreement from '../Commons/KeyResultCardAgreement';
import Contributors from '../Commons/KeyResultCardContributors';
import KeyResultCardFocusArea from '../Commons/KeyResultCardFocusArea';
import { KeyResultCardProps } from '../KeyResultCard.types';

function KeyResultCardEmbedded(props: KeyResultCardProps) {
  const owner = props.contributors.find(({ type }) => type === 'owner');

  return (
    <Box data-testid="key-result-card">
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid xs={12} sm={6} md={4} lg={3}>
          <Typography>
            {props.archivedAt && (
              <Tooltip data-testid="archived-icon" title="Archived" arrow>
                <ArchiveIcon
                  sx={{
                    width: '28px',
                    marginTop: '-4px',
                    verticalAlign: 'middle',
                  }}
                />
              </Tooltip>
            )}
            {props.isClosed && (
              <Tooltip data-testid="is_closed-icon" title="Closed" arrow>
                <LockIcon
                  sx={{
                    width: '28px',
                    marginTop: '-4px',
                    verticalAlign: 'middle',
                  }}
                />
              </Tooltip>
            )}
            {props.focusArea && <KeyResultCardFocusArea focusArea={props.focusArea} />} {props.name}
          </Typography>
        </Grid>

        <Grid xs={12} sm={6} md={4.5} lg>
          <Typography whiteSpace="pre-line">{props.howToCalculateMetric}</Typography>
        </Grid>

        <Grid
          xs={5}
          sm={6}
          md={2}
          lg={2}
          display="flex"
          alignItems="center"
          justifyContent={{ md: 'center' }}
        >
          {Boolean(owner) && (
            <Contributors
              label="Owner"
              highlightContributorId={owner!.id}
              contributors={[owner!]}
            />
          )}
        </Grid>

        <HasRole roles={['admin']}>
          <Grid display="flex" alignItems="center" justifyContent="center" flexBasis="130px">
            <KeyResultCardAgreement
              agree={props.agreementCount!.agree}
              disagree={props.agreementCount!.disagree}
              missing={props.agreementCount!['no-action']}
            />
          </Grid>

          {props.actions && (
            <Grid
              xs={4}
              sm={6}
              md={1.5}
              lg={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {props.actions}
            </Grid>
          )}
        </HasRole>
      </Grid>
    </Box>
  );
}

export default KeyResultCardEmbedded;
