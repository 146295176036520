import { LazyQueryHookOptions, gql, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { downloadFile } from '../utils';

export function useDownloadEmployees(options?: LazyQueryHookOptions<Data, Variables>) {
  const { enqueueSnackbar } = useSnackbar();

  return useLazyQuery<Data, Variables>(downloadEmployeesQuery, {
    ...options,
    onError(error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted(data) {
      downloadFile(data.downloadEmployees, { fileName: 'employees' });
    },
  });
}

interface Data {
  downloadEmployees: string;
}

interface Variables {}

export const downloadEmployeesQuery = gql`
  query DownloadEmployees {
    downloadEmployees
  }
`;
