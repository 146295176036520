import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { Objective } from '../utils';

interface GetObjectivesResult {
  getObjectives: Objective[];
}

interface ObjectiveFilter {
  deadline?: string;
  name?: string;
}

export function useGetObjectives(filter?: ObjectiveFilter) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<GetObjectivesResult>(getObjectivesQuery, {
    variables: { filter },
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
}

export const getObjectivesQuery = gql`
  query ($filter: ObjectiveFilter) {
    getObjectives(filter: $filter) {
      id
      name
      description
      deadline
    }
  }
`;
