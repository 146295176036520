import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { Objective } from '../utils';

interface EditObjectiveResult {
  editObjective: Objective;
}

export function useEditObjective(options?: MutationHookOptions<EditObjectiveResult>) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<EditObjectiveResult>(editObjectiveMutation, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: function (data) {
      enqueueSnackbar('Objective edited successfully', { variant: 'success' });
      options?.onCompleted?.(data);
    },
  });
}

export const editObjectiveMutation = gql`
  mutation ($id: String!, $data: ObjectiveInput!) {
    editObjective(objective_id: $id, data: $data) {
      id
    }
  }
`;
