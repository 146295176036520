import LockIcon from '@mui/icons-material/Lock';
import { Tooltip, Typography } from '@mui/material';

import KeyResultCardFocusArea from './KeyResultCardFocusArea';
import { FocusArea } from '../../../utils';

export interface KeyResultCardNameProps {
  name: string;
  focusArea?: FocusArea;
  isClosed?: boolean;
}

function KeyResultCardName(props: KeyResultCardNameProps) {
  return (
    <Typography fontSize={15} fontWeight={700} variant="body2">
      {props.focusArea && <KeyResultCardFocusArea focusArea={props.focusArea} />}
      {props.focusArea && ' '}
      {props.isClosed && (
        <Tooltip data-testid="is_closed-icon" title="Closed" arrow>
          <LockIcon
            sx={{
              width: '28px',
              marginTop: '-4px',
              verticalAlign: 'middle',
            }}
          />
        </Tooltip>
      )}
      {props.name}
    </Typography>
  );
}

export default KeyResultCardName;
