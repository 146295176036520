import { Button, Stack, TextField, Typography } from '@mui/material';
import { FormikContextType } from 'formik';

import { ContributorFormatted } from '../../services/mappers/contributor.mapper';
import { UserEmployee } from '../../utils';
import UserAutocomplete from '../SearchFieldsAutoComplete/UserAutocomplete/UserAutocomplete';

interface KeyResultFormContributorsProps {
  isDisabled?: boolean;
  users: UserEmployee[];
  form: FormikContextType<{
    contributors: Array<UserEmployeeWeight | null>;
    bulkContributors: Array<{
      contributors: UserEmployeeWeight[];
      weight: ContributorFormatted['weight'];
    } | null>;
  }>;
}

type UserEmployeeWeight = UserEmployee & { weight: ContributorFormatted['weight'] };

function KeyResultFormContributors({ isDisabled, form, users }: KeyResultFormContributorsProps) {
  return (
    <>
      <Typography variant="h6">Contributors</Typography>

      {form.values.contributors.map((contributor, index) => (
        <Stack
          data-testid="contributor-form"
          key={`${index}-${contributor?.id}`}
          direction="row"
          spacing={2}
        >
          <UserAutocomplete
            options={users}
            value={contributor?.id ? contributor : (null as any)}
            onChange={(_, value) => {
              const weight = contributor?.weight ?? '0';
              form.setFieldValue(`contributors[${index}]`, { ...value, weight });
            }}
            inputProps={{ label: 'User' }}
          />

          <TextField
            label="Weight"
            name={`contributors[${index}].weight`}
            value={contributor?.weight ?? '0'}
            onChange={form.handleChange}
          />

          <Button
            disabled={isDisabled}
            onClick={() =>
              form.setFieldValue(
                'contributors',
                form.values.contributors.filter((_, idx) => idx !== index)
              )
            }
          >
            Remove
          </Button>
        </Stack>
      ))}

      {form.values.bulkContributors.length > 0 && (
        <Typography variant="subtitle1">
          Please add or remove the following groups of contributors:
        </Typography>
      )}

      {form.values.bulkContributors.map((bulk, index, bulkContributors) => (
        <Stack data-testid="contributor-bulk-form" key={index} direction="row" spacing={2}>
          <UserAutocomplete<Multiple>
            multiple
            disableCloseOnSelect
            limitTags={2}
            options={users}
            value={bulk?.contributors ?? []}
            onChange={(_, values) =>
              form.setFieldValue(`bulkContributors[${index}].contributors`, values)
            }
            inputProps={{ label: 'Users' }}
          />

          <TextField
            label="Weight"
            name={`bulkContributors[${index}].weight`}
            value={bulk?.weight ?? '0'}
            onChange={form.handleChange}
          />

          <Button
            color="success"
            disabled={
              !bulk ||
              Number.isNaN(Number(bulk.weight)) ||
              Number(bulk.weight) < 1 ||
              (bulk?.contributors?.length ?? 0) === 0
            }
            onClick={() => {
              const contributors = bulk!.contributors.map((contributor) => ({
                ...contributor,
                weight: bulk!.weight,
              }));

              form.setFieldValue(
                'bulkContributors',
                bulkContributors.filter((_, idx) => idx !== index)
              );

              form.setFieldValue('contributors', form.values.contributors.concat(contributors));
            }}
          >
            Add
          </Button>

          <Button
            onClick={() =>
              form.setFieldValue(
                'bulkContributors',
                bulkContributors.filter((_, idx) => idx !== index)
              )
            }
          >
            Remove
          </Button>
        </Stack>
      ))}

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={8}>
        <Button
          fullWidth
          disabled={isDisabled}
          onClick={() => form.setFieldValue('contributors', form.values.contributors.concat(null))}
        >
          Add one contributor
        </Button>

        <Button
          fullWidth
          disabled={isDisabled}
          onClick={() =>
            form.setFieldValue('bulkContributors', form.values.bulkContributors.concat(null))
          }
        >
          Add multiple contributors
        </Button>
      </Stack>
    </>
  );
}

type Multiple = true;

export default KeyResultFormContributors;
