import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

interface CountUsersResult {
  countUsers: number;
}

export function useCountUsers(name?: string) {
  const { enqueueSnackbar } = useSnackbar();

  const options: QueryHookOptions = {
    fetchPolicy: 'no-cache',
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    variables: {
      filter: {
        name: name || '',
      },
    },
  };

  return useQuery<CountUsersResult>(countUsersQuery, options);
}

export const countUsersQuery = gql`
  query ($filter: UserFilter) {
    countUsers(filter: $filter)
  }
`;
