import { MenuItem, MenuProps, Select, SelectProps } from '@mui/material';

export interface PeriodFilterProps extends SelectProps<string> {}

function PeriodFilter(props: PeriodFilterProps) {
  return (
    <Select
      data-testid="period-filter"
      name="period"
      size="small"
      variant="standard"
      MenuProps={{ 'data-testid': 'period-filter-menu' } as Partial<MenuProps>}
      inputProps={{ 'data-testid': 'period-filter-input' }}
      sx={{ minWidth: '190px' }}
      displayEmpty={Boolean(props.placeholder)}
      {...props}
    >
      {Boolean(props.placeholder) && (
        <MenuItem value="" disabled data-testid="period-filter-placeholder">
          <em>{props.placeholder}</em>
        </MenuItem>
      )}

      {PERIODS.map((period) => (
        <MenuItem key={period} value={period} data-testid="period-filter-option">
          {period}
        </MenuItem>
      ))}
    </Select>
  );
}

export const PERIODS = ['YTD Q1', 'YTD Q2', 'YTD Q3', 'Full Year'];
export default PeriodFilter;
