import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';

export interface SearchFieldProps extends Omit<TextFieldProps, 'onSubmit'> {
  onSubmit: (value: string) => void;
  initialValue?: string | null;
  readOnly?: boolean;
}

function SearchField({ onSubmit, initialValue, readOnly, ...props }: SearchFieldProps) {
  const [value, setValue] = useState(initialValue ?? '');

  const handleOnChange = useCallback(function (event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }, []);

  const handleOnKeyDown = function (event: KeyboardEvent) {
    if (event.key === 'Enter') {
      onSubmit(value);
    }
  };

  const handleOnIconClick = function () {
    onSubmit(value);
  };

  return (
    <TextField
      {...props}
      size="small"
      InputProps={{
        readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handleOnIconClick}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
    />
  );
}

export default SearchField;
