import { Chip } from '@mui/material';

import { KeyResultStatus as KeyResultStatusType } from '../../utils';

export interface KeyResultStatusProps {
  status: KeyResultStatusType;
}

function KeyResultStatus(props: KeyResultStatusProps) {
  return (
    <Chip
      component="span"
      size="small"
      label={props.status}
      sx={{
        color: 'common.white',
        bgcolor: colorByStatus[props.status],
      }}
    />
  );
}

export const colorByStatus: Record<KeyResultStatusType, string> = {
  Completed: 'success.dark',
  'On Track': 'success.main',
  'At Risk': 'warning.light',
  Delayed: 'warning.dark',
  'Not Achieved': 'error.dark',
  'Adjust Objective': 'grey.500',
  'No Status': 'grey.400',
  'Partially Achieved': 'warning.light',
  Overachieved: 'info.dark',
} as const;

export default KeyResultStatus;
