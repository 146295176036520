import ArFlag from '../../assets/flags/ar.png';
import BrFlag from '../../assets/flags/br.png';
import ClarelFlag from '../../assets/flags/clarel.png';
import EsFlag from '../../assets/flags/es.png';
import GLobalFlag from '../../assets/flags/global.png';
import GroupFlag from '../../assets/flags/group.png';
import PtFlag from '../../assets/flags/pt.png';
import { BusinessUnits, Countries } from '../types/employee.types';
import { FocusArea } from '../types/keyResult.types';

export default function getIconAndCountryName(id: FocusArea | Countries | BusinessUnits) {
  switch (id) {
    case 'argentina':
      return { FlagIcon: ArFlag, countryName: 'Argentina' };
    case 'brazil':
      return { FlagIcon: BrFlag, countryName: 'Brazil' };
    case 'clarel':
      return { FlagIcon: ClarelFlag, countryName: 'Clarel' };
    case 'spain':
      return { FlagIcon: EsFlag, countryName: 'Spain' };
    case 'group':
      return { FlagIcon: GroupFlag, countryName: 'Group' };
    case 'portugal':
      return { FlagIcon: PtFlag, countryName: 'Portugal' };
    case 'global':
      return { FlagIcon: GLobalFlag, countryName: 'Global' };
    default:
      return null;
  }
}
