export const FOCUS_AREAS = [
  'argentina',
  'brazil',
  'spain',
  'portugal',
  'clarel',
  'group',
  'global',
] as const;
export type FocusArea = (typeof FOCUS_AREAS)[number];

export interface KeyResult {
  id: string;
  name: string;
  how_to_calculate_metric: string;
  status: KeyResultStatus;
  deadline: string;
  updatedAt: Date;
  fiscal_year: string;
  focus_area: FocusArea;
  weight_multiplier: number;
  ranking_index?: number;
  is_closed?: boolean;
  archived_at?: Date;
}

export const KEY_RESULT_STATUS_ORDERED = [
  {
    type: 'Initial',
    statuses: ['No Status'],
  },
  {
    type: 'Ongoing',
    statuses: ['Adjust Objective', 'At Risk', 'Delayed', 'On Track'],
  },
  {
    type: 'Final',
    statuses: ['Completed', 'Not Achieved', 'Partially Achieved', 'Overachieved'],
  },
] as const;

export const KEY_RESULT_STATUS = KEY_RESULT_STATUS_ORDERED.flatMap(({ statuses }) => statuses);

export type KeyResultStatus = (typeof KEY_RESULT_STATUS)[number];
