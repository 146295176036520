import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';

import { UserEmployee } from '../../../utils';
import { filterOptions } from '../../../utils/filters';

export interface UserAutocompleteProps<M extends boolean = Multiple>
  extends Omit<AutocompleteProps<UserEmployee, M, DisableClearable, FreeSolo>, 'renderInput'> {
  inputProps?: Partial<TextFieldProps>;
}

type Multiple = false;
type DisableClearable = true;
type FreeSolo = false;

function UserAutocomplete<M extends boolean = Multiple>({
  inputProps,
  ...props
}: UserAutocompleteProps<M>) {
  return (
    <Autocomplete
      data-testid="user-autocomplete"
      {...props}
      renderInput={(params) => <TextField {...params} {...inputProps} />}
      getOptionLabel={getPresentationName}
      isOptionEqualToValue={(user, value) => user.id === value?.id}
      filterOptions={(options, state) =>
        filterOptions(options, state.inputValue, getPresentationName)
      }
      renderOption={(props, user) => (
        <Typography
          {...props}
          key={user.id}
          data-testid="user-autocomplete-option"
          component="li"
          sx={{ flexWrap: 'wrap' }}
        >
          {getPresentationName(user)}

          {!user.is_active && (
            <Typography variant="body2" color="text.secondary" flexBasis="100%">
              deactivated
            </Typography>
          )}
        </Typography>
      )}
      disableClearable
      fullWidth
    />
  );
}

function getPresentationName(user: UserEmployee) {
  return user.employee.preferredName;
}

export default UserAutocomplete;
