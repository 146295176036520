import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { KeyResultFormattedResponse, formatKeyResult } from './mappers/keyResult.mapper';
import { QueryResponse, buildQueryObject } from './service.utils';
import { useContext, userContext } from '../context';
import {
  BooleanObject,
  BooleanObjectType,
  KeyResult,
  KeyResultUpdate,
  Objective,
  RemoveFalse,
} from '../utils';
import { Employee } from '../utils/types/employee.types';

export function useGetAppraisals<F extends BooleanObject<MappedFields>>(
  name: string,
  fields: F,
  options: QueryHookOptions<Data<QueryResponse<typeof fields, MappedFields>>, Variables>
) {
  const { enqueueSnackbar } = useSnackbar();
  const { user: loggedUser } = useContext(userContext);

  const { data, ...result } = useQuery<Data<QueryResponse<typeof fields, MappedFields>>, Variables>(
    buildAppraisalsQuery(name, fields),
    {
      ...options,
      onError(error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    }
  );

  const mappedData = useMemo(
    () =>
      (data?.getAppraisals || []).map((appraisal) =>
        formatKeyResult(loggedUser!, appraisal)
      ) as Array<KeyResultFormattedResponse<RemoveFalse<F>>>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.getAppraisals]
  );

  return { ...result, data: { getAppraisals: mappedData } };
}

export function buildAppraisalsQuery(name: string, fields: BooleanObjectType) {
  return gql`
    query ${name} ($filter: AppraisalFilter!) {
      getAppraisals(filter: $filter) {
        ${buildQueryObject(fields)}
      }
    }
  `;
}

interface Data<T> {
  getAppraisals: T[];
}

interface Variables {
  filter: {
    employee_id: string;
    year: string;
    period: string;
  };
}
interface MappedFields extends KeyResult {
  objective: Objective;
  appraisals: Array<
    KeyResultUpdate & {
      employee: Employee;
      directManager: Employee;
    }
  >;
}
