import { SxProps } from '@mui/material';

import { theme } from '../../App/theme';

export const selectableItem: SxProps<typeof theme> = {
  transition: ({ transitions }) =>
    transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.standard,
    }),
  '&:hover': {
    transform: 'scale(1.03, 1.02)',
    boxShadow: ({ palette }) =>
      `0px 6px 12px -2px ${palette.shadow.default}` +
      `, 0px 3px 7px -3px ${palette.shadow.default}`,
  },
};
