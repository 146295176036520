import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

export interface AgreementModalProps extends DialogProps {
  onAgree: () => void;
  onDisagree: () => void;
  onCancel: () => void;
}

function AgreementModal({ onAgree, onDisagree, onCancel, ...props }: AgreementModalProps) {
  return (
    <Dialog data-testid="agreement-modal" {...props}>
      <DialogTitle>Key result validation</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Please read the KR and its description carefully, validate if the target description and
          weight assigned to you are correct.
        </DialogContentText>

        <DialogContentText marginTop={2}>
          If all the information is correct, press agree. If something is wrong, press disagree.
        </DialogContentText>

        <DialogContentText marginTop={2}>
          After agreeing or not with the KR your answer cannot be changed.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="success" onClick={onAgree}>
          Agree
        </Button>

        <Button variant="contained" color="error" onClick={onDisagree}>
          Disagree
        </Button>

        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AgreementModal;
