import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { userContext } from '../../context';
import { useLocalStorage } from '../../hooks';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import { useGetCurrentUser } from '../../services/useGetCurrentUser';
import { LocalStorageKeys, Route } from '../../utils';

function PrivatePage() {
  const getCurrentUser = useGetCurrentUser();
  const { user: contextUser, setUser } = useContext(userContext);
  const [token] = useLocalStorage(LocalStorageKeys.TOKEN);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const user = contextUser || getCurrentUser.data?.getCurrentUser;
  const shouldUpdateUser = !contextUser && getCurrentUser.data?.getCurrentUser;
  const shouldForward = Boolean(contextUser);

  if (!user && getCurrentUser.loading) {
    return <div>Loading</div>;
  }

  if (!user) {
    if (token) {
      enqueueSnackbar('Your session expired. Please login again.', { variant: 'warning' });
    }

    return <Navigate to={Route.LOGIN} state={location} />;
  }

  if (shouldUpdateUser) {
    setUser(user);
  }

  if (shouldForward) {
    const pageToRedirect = user?.forcePasswordChangeFlag ? <ChangePassword /> : <Outlet />;
    return pageToRedirect;
  }

  return null;
}

export default PrivatePage;
