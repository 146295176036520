import { useGrowthBook } from '@growthbook/growthbook-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { userContext } from './userContext';
import { UserContext } from './userContext.types';
import { UserEmployee } from '../../utils';

export interface UserProviderProps {
  user?: UserEmployee;
  children?: ReactNode | undefined;
}

function UserProvider(props?: UserProviderProps) {
  const [user, setUser] = useState<UserContext['user']>(props?.user);
  const gb = useGrowthBook();

  useEffect(() => {
    if (!user || !gb) return;
    gb.setAttributes({
      id: user.email,
    });
  }, [user, gb]);

  const context = useMemo<UserContext>(
    () => ({
      user,
      setUser,
      includesLoggedUser(values) {
        return user ? Boolean(values.find(({ id }) => id === user.id)) : false;
      },
      isAdmin: user?.roles ? user.roles.map((role) => role.toLowerCase()).includes('admin') : false,
    }),
    [user]
  );

  return <userContext.Provider value={context}>{props?.children}</userContext.Provider>;
}

export default UserProvider;
