import { Help } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { FormikContextType } from 'formik';

export interface KeyResultFormIsClosedProps {
  isDisabled?: boolean;
  form: FormikContextType<{ is_closed: boolean }>;
}

function KeyResultFormIsClosed({ isDisabled, form }: KeyResultFormIsClosedProps) {
  return (
    <FormControlLabel
      data-testid="is_closed"
      control={
        <Checkbox
          disabled={isDisabled}
          checked={form.values.is_closed}
          onChange={(_, isChecked) => form.setFieldValue('is_closed', isChecked)}
        />
      }
      label={
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography>This KR is closed</Typography>

          <Tooltip title="If checked this will be closed and cannot be modified by non admin users">
            <Help color="action" fontSize="small" />
          </Tooltip>
        </Stack>
      }
    />
  );
}

export default KeyResultFormIsClosed;
