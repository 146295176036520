import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ElementType } from 'react';
import { Link, To } from 'react-router-dom';

import { Objective } from '../../utils';
import HasRole from '../HasRole/HasRole';
import PageCard, { PageCardProps } from '../PageCard/PageCard';

export type ObjectiveCardProps<C extends ElementType> = PageCardProps<C> & {
  objective: Objective;
  onEditNavigation?: {
    to: To;
    state?: any;
  };
};

function ObjectiveCard<C extends ElementType>({
  objective,
  onEditNavigation,
  ...props
}: ObjectiveCardProps<C>) {
  return (
    <PageCard spacing="compact" {...props}>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid xs={12} md={5}>
          <Typography color="primary.main" sx={{ textDecorationLine: 'underline' }}>
            {objective.name}
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Deadline:
            <Typography component="span" variant="body2" color="text.primary" fontWeight="medium">
              {objective.deadline}
            </Typography>
          </Typography>
        </Grid>

        <Grid xs={12} md={onEditNavigation ? 5 : 7}>
          <Typography variant="body2" color="text.secondary">
            Description:
          </Typography>

          <Typography variant="body2" color="text.primary" fontWeight="medium">
            {objective.description}
          </Typography>
        </Grid>

        {!!onEditNavigation && (
          <HasRole roles={['admin']}>
            <Grid xs={12} md={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Button color="warning" component={Link} {...onEditNavigation}>
                Edit
              </Button>
            </Grid>
          </HasRole>
        )}
      </Grid>
    </PageCard>
  );
}

export default ObjectiveCard;
