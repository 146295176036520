import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { Objective } from '../utils';

interface CreateObjectiveResult {
  createObjective: Objective;
}

export function useCreateObjective(options?: MutationHookOptions<CreateObjectiveResult>) {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation<CreateObjectiveResult>(createObjectiveMutation, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: function (data) {
      enqueueSnackbar('Objective created successfully', { variant: 'success' });
      options?.onCompleted?.(data);
    },
  });
}

export const createObjectiveMutation = gql`
  mutation ($data: ObjectiveInput!) {
    createObjective(data: $data) {
      id
    }
  }
`;
