import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { ContributorAgreement, Contributor } from '../utils';

export interface UpdateAgreementResult {
  updateKeyResultsToUsers: Contributor;
}

export interface UpdateAgreementVariables {
  krId: string;
  agreement: ContributorAgreement;
}

export function useUpdateAgreement(
  options?: MutationHookOptions<UpdateAgreementResult, UpdateAgreementVariables>
) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<UpdateAgreementResult, UpdateAgreementVariables>(updateAgreementMutation, {
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    ...options,
  });
}

export const updateAgreementMutation = gql`
  mutation UpdateAgreement($krId: String!, $agreement: String!) {
    agreementUserToKeyResult(kr_id: $krId, agreement: $agreement) {
      id
    }
  }
`;
