import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { KeyResultUpdate } from '../utils';

export function useApproveAppraisal(options?: MutationHookOptions<Data, Variables>) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<Data, Variables>(approveAppraisalMutation, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: function (data, opts) {
      const message = opts?.variables?.isApproved ? 'approved' : 'disapproved';

      enqueueSnackbar(`Appraisal ${message}`, { variant: 'success' });
      options?.onCompleted?.(data, opts);
    },
  });
}

export const approveAppraisalMutation = gql`
  mutation ($appraisalId: String!, $isApproved: Boolean!, $comment: String) {
    createKeyResultAppraisalApproval(
      appraisal_id: $appraisalId
      is_approved: $isApproved
      comment: $comment
    ) {
      id
    }
  }
`;

interface Data {
  createKeyResultAppraisalApproval: KeyResultUpdate;
}

interface Variables {
  appraisalId: string;
  isApproved: boolean;
  comment?: string;
}
