import { format as formatDate } from 'date-fns';

import Definition from '../../Definition/Definition';
import { DefinitionProps } from '../../Definition/Definition';

export interface KeyResultCardDeadlineProps extends Omit<DefinitionProps, 'label' | 'value'> {
  deadline: Date;
}

function KeyResultCardDeadline({ deadline, ...props }: KeyResultCardDeadlineProps) {
  return <Definition {...props} label="Deadline" value={formatDate(deadline, 'dd/MM/yyyy')} />;
}

export default KeyResultCardDeadline;
