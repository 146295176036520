import { gql } from '@apollo/client';

import { BooleanObjectType, Leaves } from '../utils';

/**
 * This type filter out the properties from `QRes` that are `false` or `undefined` on `BObj`.
 *
 * @param BObj Is the {@link BooleanObjectType}.
 * @param QRes Is the query/mutation raw response.
 */
export type QueryResponse<BObj, QRes> = {
  [Key in keyof BObj as BObj[Key] extends false ? never : Key]: Key extends keyof QRes
    ? BObj[Key] extends true
      ? QRes[Key]
      : QRes[Key] extends any[]
      ? Array<QueryResponse<BObj[Key], QRes[Key][number]>>
      : QueryResponse<BObj[Key], QRes[Key]>
    : never;
};

/**
 * This type filter out the properties from `FObj` that are not included on `BObj` nor `MFields`.
 * But if a property is included on `MFields` then all properties on that `MFields`'s value must be
 * included on `BObj` otherwise the key will be filtered out.
 *
 * @param FObj Is the formatted object.
 * @param BObj Is the {@link BooleanObjectType}.
 * @param MFields Is the mapped fields. An interface where the keys are the `FObj`'s keys and the values are an Union Type with the required fields.
 */
export type FormattedResponse<FObj, BObj, MFields, BObjKeys = Leaves<BObj>> = {
  [Key in keyof FObj as Key extends keyof MFields
    ? MFields[Key] extends Extract<BObjKeys, MFields[Key]>
      ? Key
      : never
    : Key extends keyof BObj
    ? Key
    : never]: FObj[Key] extends [infer NestedFObj, infer NestedMFields]
    ? Key extends keyof BObj
      ? NestedFObj extends Array<any>
        ? Array<FormattedResponse<NestedFObj[number], BObj[Key], NestedMFields>>
        : FormattedResponse<NestedFObj, BObj[Key], NestedMFields>
      : never
    : FObj[Key] extends [infer NestedFObj, infer NestedMFields, infer Alias]
    ? Alias extends keyof BObj
      ? NestedFObj extends Array<any>
        ? Array<FormattedResponse<NestedFObj[number], BObj[Alias], NestedMFields>>
        : FormattedResponse<NestedFObj, BObj[Alias], NestedMFields>
      : never
    : FObj[Key];
};

interface BuildQueryOptions {
  fields: BooleanObjectType;
  filters: Array<[string, string, string]>;
  name: string;
  query: string;
  type?: 'query' | 'mutation';
}

export function buildQuery(options: BuildQueryOptions) {
  const type = options.type ?? 'query';

  return gql`
    ${type} ${options.name} (${buildQueryFilters(options.filters)}) {
      ${options.query}(${buildQueryArguments(options.filters)}) {
        ${buildQueryObject(options.fields)}
      }
    }
  `;
}

function buildQueryFilters(filters: Array<[string, string, string]>): string {
  return filters
    .reduce((prev, [param, type]) => prev.concat(`$${param}: ${type}`), [] as string[])
    .join(', ');
}

function buildQueryArguments(filters: Array<[string, string, string]>): string {
  return filters
    .reduce((prev, [param, _, arg]) => prev.concat(`${arg}: $${param}`), [] as string[])
    .join(', ');
}

export function buildQueryObject(object: BooleanObjectType): string {
  return Object.entries(object)
    .reduce(function (prev, [key, value]) {
      if (typeof value === 'boolean') {
        return value ? prev.concat(key) : prev;
      }

      return prev.concat(`${key} {`, buildQueryObject(value), '}');
    }, [] as string[])
    .join('\n');
}
