import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export type PasswordInputProps = TextFieldProps & { hidePasswordToggle?: Boolean };

function PasswordInput(props: PasswordInputProps) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  let inputProps = props.InputProps || {};

  if (props.hidePasswordToggle !== true) {
    inputProps = {
      ...props.InputProps,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  return <TextField {...props} type={showPassword ? 'text' : 'password'} InputProps={inputProps} />;
}

export default PasswordInput;
