import { Backdrop, Box, Drawer, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import PageLayoutArrow from './PageLayoutArrow';
import PageLayoutList from './PageLayoutList';
import PageLayoutLogo from './PageLayoutLogo';
import PageLayoutProfile from './PageLayoutProfile';

function PageLayout() {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const shouldHideForMobile = isMobile && !isOpen;
  const closeMenu = () => setIsOpen(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        open={isOpen}
        sx={{
          width: isOpen ? '220px' : '60px',
          flexShrink: 0,
          transition: ({ transitions }) =>
            transitions.create('width', {
              easing: transitions.easing.sharp,
              duration: transitions.duration.leavingScreen,
            }),
          ...(isOpen && {
            transition: ({ transitions }) =>
              transitions.create('width', {
                easing: transitions.easing.easeOut,
                duration: transitions.duration.enteringScreen,
              }),
          }),
          ...(isMobile && {
            position: 'absolute',
            ...(!isOpen && { width: 0 }),
          }),
          '& .MuiDrawer-paper': {
            width: 'inherit',
            boxSizing: 'border-box',
            alignItems: 'center',
            paddingX: isOpen ? 2 : 1,
            paddingY: 2,
            borderColor: 'border.default',
            overflowY: 'visible',
            ...(shouldHideForMobile && { padding: 0 }),
            '& > :last-child': {
              marginTop: 2,
            },
          },
        }}
      >
        <PageLayoutArrow
          isOpen={isOpen}
          spacing={mainBiggestPaddingHorizontal}
          onClick={() => setIsOpen((value) => !value)}
        />

        {!shouldHideForMobile && (
          <>
            <PageLayoutLogo isOpen={isOpen} />
            <PageLayoutProfile isOpen={isOpen} />
            <PageLayoutList isOpen={isOpen} onClick={() => isMobile && closeMenu()} />
          </>
        )}
      </Drawer>

      {isMobile && (
        <Backdrop
          open={isOpen}
          onClick={closeMenu}
          sx={{ zIndex: ({ zIndex }) => zIndex.drawer - 1 }}
        />
      )}

      <Box
        component="main"
        sx={{
          minHeight: '100vh',
          paddingX: isOpen && !isMobile ? mainBiggestPaddingHorizontal : 5,
          paddingY: mainPaddingVertical,
          flexGrow: 1,
          bgcolor: 'background.page',
          overflow: 'hidden',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export const mainPaddingVertical = 5;
export const mainBiggestPaddingHorizontal = mainPaddingVertical + 2;

export default PageLayout;
