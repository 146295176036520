import { Box } from '@mui/material';

import LogoImg from '../../assets/logo.png';

export interface PageLayoutLogoProps {
  isOpen: boolean;
}

function PageLayoutLogo(props: PageLayoutLogoProps) {
  const logoSize = props.isOpen ? '80px' : '55px';

  return (
    <Box
      component="img"
      src={LogoImg}
      alt="logo"
      sx={{ marginTop: -2, width: logoSize, height: logoSize }}
    />
  );
}

export default PageLayoutLogo;
