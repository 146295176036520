import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Route } from '../utils/constants/route';

interface EditUserResult {
  editUser: { id: string; name: string; email: string };
}

export function useEditUser() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const result = useMutation<EditUserResult>(editUserMutation, {
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: (data) => {
      enqueueSnackbar('User edit successfully', { variant: 'success' });
      navigate(Route.LIST_USERS);
    },
  });
  return result;
}

export const editUserMutation = gql`
  mutation (
    $editUserId: String!
    $roles: [RoleEnum!]!
    $name: String!
    $password: String
    $isActive: Boolean!
  ) {
    editUser(id: $editUserId, roles: $roles, name: $name, password: $password, active: $isActive) {
      id
      name
      email
    }
  }
`;
