export function filterOptions<T>(
  items: T[],
  inputValue: string,
  getText: (item: T) => string
): T[] {
  return items.filter((item) => {
    const itemText = getText(item)
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const inputText = inputValue
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const inputTerms = inputText.split(' ');
    return inputTerms.every((term) => itemText.includes(term));
  });
}
