import { useSnackbar } from 'notistack';
import { ReactNode, useContext, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { userContext } from '../../context';
import { Route } from '../../utils';

export interface HasRoleProps {
  roles: string[];
  children?: ReactNode;
}

function HasRole({ children, roles }: HasRoleProps) {
  const { user } = useContext(userContext);
  const { enqueueSnackbar } = useSnackbar();

  const [hasPermission] = useState(
    () => user?.roles?.some((role: string) => roles.includes(role)) ?? false
  );

  if (children) {
    return hasPermission ? <>{children}</> : null;
  }

  if (!hasPermission) {
    enqueueSnackbar('You are not allowed to see this page', { variant: 'warning' });
  }

  return hasPermission ? <Outlet /> : <Navigate to={Route.HOME} replace />;
}

export default HasRole;
