import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';

import { EmployeeFormatted } from '../../../services/mappers/employee.mapper';
import { filterOptions } from '../../../utils/filters';

export interface EmployeeAutocompleteProps
  extends Omit<
    AutocompleteProps<EmployeeAutocompleteOption, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  inputProps?: Partial<TextFieldProps>;
}

export type EmployeeAutocompleteOption = Pick<EmployeeFormatted, 'id'> & {
  groupBy?: string;
  preferredName?: string;
};
type Multiple = false;
type DisableClearable = true;
type FreeSolo = false;

function EmployeeAutocomplete({ inputProps, ...props }: EmployeeAutocompleteProps) {
  return (
    <Autocomplete
      data-testid="employee-autocomplete"
      {...props}
      renderInput={(params) => <TextField {...params} {...inputProps} />}
      getOptionLabel={getPresentationName}
      isOptionEqualToValue={(employee, value) => employee.id === value?.id}
      filterOptions={(options, state) =>
        filterOptions(options, state.inputValue, getPresentationName)
      }
      renderOption={(props, employee) => (
        <Typography
          {...props}
          key={employee.id}
          data-testid="employee-autocomplete-option"
          component="li"
          sx={{ flexWrap: 'wrap' }}
        >
          {getPresentationName(employee)}
        </Typography>
      )}
      disableClearable
      fullWidth
    />
  );
}

function getPresentationName(employee: EmployeeAutocompleteOption) {
  return employee.preferredName!;
}

export default EmployeeAutocomplete;
