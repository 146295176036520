import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { ContributorFormatted, formatContributor } from './mappers/contributor.mapper';
import { RawContributor, formatAgreementCount } from './mappers/keyResultAgreements.mapper';
import { FocusArea, KeyResult, Objective } from '../utils';

interface GetObjectiveByIdResult {
  getObjectiveById: Objective & {
    keyResults: Array<KeyResult & { contributors: RawContributor[] }>;
  };
}

export function useGetObjectiveById(id: string) {
  const { enqueueSnackbar } = useSnackbar();

  const { data, ...result } = useQuery<GetObjectiveByIdResult>(getObjectiveByIdQuery, {
    variables: { id },
    fetchPolicy: 'no-cache',
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const mappedData = useMemo(
    function () {
      const objective = data?.getObjectiveById;
      const keyResults = objective?.keyResults ?? [];

      if (!objective) {
        return null;
      }

      return {
        ...objective,
        keyResults: keyResults.map((keyResult) => ({
          id: keyResult.id,
          name: keyResult.name,
          focusArea: keyResult.focus_area.toLowerCase() as FocusArea,
          description: keyResult.how_to_calculate_metric,
          owner: (keyResult.contributors?.map(formatContributor) as ContributorFormatted[]).find(
            ({ type }) => type.toLocaleLowerCase() === 'owner'
          ),
          agreementCount: formatAgreementCount(keyResult.contributors),
          isClosed: keyResult.is_closed,
          archived_at: keyResult.archived_at,
        })),
      };
    },
    [data]
  );

  return { data: { getObjectiveById: mappedData }, ...result };
}

export const getObjectiveByIdQuery = gql`
  query ($id: String!) {
    getObjectiveById(id: $id) {
      id
      name
      description
      deadline
      keyResults {
        id
        name
        focus_area
        archived_at
        how_to_calculate_metric
        is_closed
        contributors {
          user_type
          user_kr_weight
          agreement
          user_id
          name
          is_active
          employee {
            preferredName
            profilePicture
          }
        }
      }
    }
  }
`;
