import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

export function useCountEmployees(options?: QueryHookOptions<Data, Variables>) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery<Data, Variables>(countEmployeesQuery, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
}

interface Data {
  countEmployees: number;
}

interface Variables {
  filter?: {
    name?: string;
  };
}

export const countEmployeesQuery = gql`
  query CountEmployees($filter: EmployeeFilter) {
    countEmployees(filter: $filter)
  }
`;
