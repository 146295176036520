import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

function PageViewMeasurement() {
  const location = useLocation();

  useEffect(
    function () {
      gtag?.('event', 'page_view');
    },
    [location]
  );

  return <Outlet />;
}

export default PageViewMeasurement;
