import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { validatePasswords } from './ChangePassword.utils';
import { Breadcrumbs, PageCard, PageLoading } from '../../components';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { useChangePassword } from '../../services';
import { Route } from '../../utils';

export interface ChangePasswordProps {
  hideBreadcrumbs?: Boolean;
}

function ChangePassword(props: ChangePasswordProps) {
  const [changePassword] = useChangePassword();
  const formik = useFormik({
    initialValues: { currentPassword: '', newPassword: '', confirmationPassword: '' },
    validate: validatePasswords,
    onSubmit: function (data, { setSubmitting }) {
      setSubmitting(true);
      changePassword({
        variables: { oldPassword: data.currentPassword, newPassword: data.newPassword },
      }).finally(() => setSubmitting(false));
    },
  });

  return (
    <Stack height="100vh" justifyContent="center" bgcolor="background.page">
      <Container maxWidth="md">
        <PageLoading isLoading={formik.isSubmitting} />

        <Stack spacing={1.5}>
          {!props.hideBreadcrumbs && (
            <Breadcrumbs backLink={{ label: 'Logout', to: Route.LOGOUT }} />
          )}

          <PageCard>
            <Typography fontSize="large">Change password</Typography>

            <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit} sx={{ mt: 4 }}>
              <Stack spacing={2}>
                <PasswordInput
                  id="currentPassword"
                  name="currentPassword"
                  label="Current password"
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  fullWidth
                />

                <PasswordInput
                  id="newPassword"
                  name="newPassword"
                  label="New password"
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  error={!!formik.errors.newPassword}
                  fullWidth
                />

                {formik.errors.newPassword && (
                  <Typography color="error">{formik.errors.newPassword}</Typography>
                )}

                <PasswordInput
                  id="confirmationPassword"
                  name="confirmationPassword"
                  label="Confirm the password"
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  value={formik.values.confirmationPassword}
                  error={!!formik.errors.confirmationPassword}
                  fullWidth
                />

                {formik.errors.confirmationPassword && (
                  <Typography color="error">{formik.errors.confirmationPassword}</Typography>
                )}

                <Button
                  data-testid="change-password-button"
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={
                    formik.isSubmitting ||
                    !formik.values.currentPassword ||
                    !formik.values.newPassword ||
                    !formik.values.confirmationPassword ||
                    !formik.isValid
                  }
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </PageCard>
        </Stack>
      </Container>
    </Stack>
  );
}

export default ChangePassword;
