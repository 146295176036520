import { Divider, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { format as formatDate } from 'date-fns';

import { showWeightMultiplierFriendly } from '../../../services/mappers/keyResult.mapper';
import Definition from '../../Definition/Definition';
import KeyResultStatus from '../../KeyResultStatus/KeyResultStatus';
import KeyResultCardContributors from '../Commons/KeyResultCardContributors';
import KeyResultCardDeadline from '../Commons/KeyResultCardDeadline';
import KeyResultCardDescription from '../Commons/KeyResultCardDescription';
import KeyResultCardHighlighted from '../Commons/KeyResultCardHighlighted';
import KeyResultCardName from '../Commons/KeyResultCardName';
import KeyResultCardObjective from '../Commons/KeyResultCardObjective';
import { KeyResultCardProps } from '../KeyResultCard.types';

function KeyResultCardDetails(props: KeyResultCardProps) {
  const owner = props.contributors.find(({ type }) => type === 'owner');

  return (
    <KeyResultCardHighlighted
      data-testid="key-result-card-details"
      highlightCard={props.highlightCard}
      agreement={props.agreement}
    >
      <Stack direction="row" alignItems="center" spacing={4}>
        {Boolean(props.objective) && (
          <KeyResultCardObjective
            name={props.objective!.name}
            description={props.objective!.description}
          />
        )}

        {Boolean(props.status) && <KeyResultStatus status={props.status!} />}

        {Boolean(props.actions) && (
          <Stack direction="row" flex={1} justifyContent="flex-end">
            {props.actions}
          </Stack>
        )}
      </Stack>

      <Grid container marginTop={2} rowGap={3} columnGap={{ md: 8, lg: 16, xl: 28 }}>
        <Grid xs={12} md>
          <Stack spacing={3}>
            <KeyResultCardName
              name={props.name}
              focusArea={props.focusArea}
              isClosed={props.isClosed}
            />
            <KeyResultCardDescription description={props.howToCalculateMetric} />
          </Stack>
        </Grid>

        <Grid container rowGap={1.5} columnGap={3} xs={12} flexBasis={{ md: '305px' }}>
          <Grid flexBasis="80px">
            <KeyResultCardContributors
              data-testid="owner"
              label="Owner"
              contributors={owner ? [owner] : []}
              highlightContributorId={owner?.id}
            />
          </Grid>

          <Grid flexBasis="200px" xs>
            <KeyResultCardContributors
              data-testid="contributors"
              contributors={props.contributors.filter(({ type }) => type !== 'owner')}
            />
          </Grid>

          <Grid xs={12}>
            <Divider sx={{ marginX: -1 }} />
          </Grid>

          {typeof props.weights?.multiplier === 'number' && (
            <Grid data-testid="weight-multiplier" xs={12}>
              <Definition
                direction="row"
                label="Weight multiplier"
                valueJustify="flex-start"
                value={showWeightMultiplierFriendly(props.weights.multiplier)}
              />
            </Grid>
          )}

          {typeof props.weights?.target === 'number' && (
            <Grid data-testid="target-weight" flexBasis="125px">
              <Definition
                direction="row"
                label="Target weight"
                valueJustify="flex-start"
                value={props.weights.target}
              />
            </Grid>
          )}

          {props.weights?.showReal && (
            <Grid data-testid="real-weight" flexBasis="115px" xs>
              <Definition
                direction="row"
                label="Real weight"
                valueJustify="flex-start"
                value={props.weights.real ?? 0}
                {...(typeof props.weights.multiplier === 'number' &&
                  typeof props.weights.target === 'number' && {
                    tooltipText: `${props.weights.target} x ${showWeightMultiplierFriendly(
                      props.weights.multiplier
                    )} = ${props.weights.real}`,
                  })}
              />
            </Grid>
          )}

          {Boolean(props.lastUpdate) && (
            <Grid xs={12}>
              <Definition
                direction="row"
                label="Last update"
                valueJustify="flex-start"
                value={formatDate(props.lastUpdate!, 'dd/MM/yyyy HH:mm O')}
              />
            </Grid>
          )}

          {Boolean(props.deadline) && (
            <Grid xs={12}>
              <KeyResultCardDeadline
                direction="row"
                valueJustify="flex-start"
                deadline={props.deadline!}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </KeyResultCardHighlighted>
  );
}

export default KeyResultCardDetails;
