import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { User } from '../utils';

interface CreateUserResult {
  createUser: User;
}

interface CreateUserVariables {
  name: string;
  email: string;
  password: string;
  roles: string[];
}

export function useCreateUser(
  options?: MutationHookOptions<CreateUserResult, CreateUserVariables>
) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<CreateUserResult, CreateUserVariables>(createUserMutation, {
    ...options,
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: function (data) {
      enqueueSnackbar('User created successfully', { variant: 'success' });
      options?.onCompleted?.(data);
    },
  });
}

export const createUserMutation = gql`
  mutation CreateUser($email: String!, $password: String!, $roles: [RoleEnum!]!, $name: String!) {
    createUser(email: $email, password: $password, roles: $roles, name: $name) {
      id
      email
    }
  }
`;
