export function getFilterableYears() {
  const initialYear = 2022;
  const currentYear = new Date().getFullYear();
  const filterableYears: string[] = [];

  for (let year = currentYear + 1; year >= initialYear; year--) {
    filterableYears.push(String(year));
  }
  return filterableYears;
}
