import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { EmployeeFormattedResponse, formatEmployee } from './mappers/employee.mapper';
import { QueryResponse, buildQueryObject } from './service.utils';
import { BooleanObject, BooleanObjectType, RemoveFalse, User } from '../utils';
import { Employee } from '../utils/types/employee.types';

export function useGetEmployeesByManager<F extends BooleanObject<MappedFields>>(
  name: string,
  fields: F,
  options: QueryHookOptions<Data<QueryResponse<typeof fields, MappedFields>>, Variables>
) {
  const { enqueueSnackbar } = useSnackbar();

  const { data, ...result } = useQuery<Data<QueryResponse<typeof fields, MappedFields>>, Variables>(
    buildEmployeesByManagerQuery(name, fields),
    {
      ...options,
      onError(error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    }
  );

  const mappedData = useMemo(
    () =>
      (data?.getEmployeesByManager || []).map(formatEmployee) as Array<
        EmployeeFormattedResponse<RemoveFalse<F>>
      >,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.getEmployeesByManager]
  );

  return { ...result, data: { getEmployeesByManager: mappedData } };
}

export function buildEmployeesByManagerQuery(name: string, fields: BooleanObjectType) {
  return gql`
    query ${name} ($employee_manager_id: String!) {
      getEmployeesByManager(employee_manager_id: $employee_manager_id) {
        ${buildQueryObject(fields)}
      }
    }
  `;
}

interface Data<T> {
  getEmployeesByManager: T[];
}

interface Variables {
  employee_manager_id: string;
}

interface MappedFields extends Employee {
  user?: User;
}
