import KeyResultFilterViewMode from './KeyResultFilterViewMode';
import { UserEmployee } from '../../utils';
import Filter, { FilterFieldWrapper, FilterHighlightedFieldWrapper } from '../Filter/Filter';
import FiscalYearFilter from '../FiscalYearFilter/FiscalYearFilter';
import { KeyResultCardListVariants } from '../KeyResultCardList/KeyResultCardList';
import SearchField from '../SearchField/SearchField';
import UserAutocomplete from '../SearchFieldsAutoComplete/UserAutocomplete/UserAutocomplete';

export interface KeyResultFilterProps {
  users: UserEmployee[];
  selectedUser: UserEmployee;
  selectedFiscalYear: string;
  selectedViewMode: KeyResultCardListVariants;
  initialName: string | null;
  onChangeFilter: (name: string, value: string) => void;
  onChangeViewMode: (viewMode: KeyResultCardListVariants) => void;
  readOnly?: boolean;
  showUserFilter?: boolean;
}

function KeyResultFilter(props: KeyResultFilterProps) {
  return (
    <Filter shouldHighlight={props.showUserFilter}>
      {props.showUserFilter && (
        <FilterHighlightedFieldWrapper>
          <UserAutocomplete
            data-testid="user-filter"
            readOnly={props.readOnly}
            options={props.users}
            value={props.selectedUser}
            onChange={(_, user) => props.onChangeFilter('user', user.id)}
            inputProps={{ variant: 'standard' }}
            sx={{
              width: '270px',
              display: 'flex',
              alignItems: 'end',
              '& input': { fontWeight: 'medium' },
            }}
          />
        </FilterHighlightedFieldWrapper>
      )}

      <FilterFieldWrapper>
        <FiscalYearFilter
          readOnly={props.readOnly}
          value={props.selectedFiscalYear}
          onChange={(event) => props.onChangeFilter('fiscalYear', event.target.value!)}
        />
      </FilterFieldWrapper>

      <FilterFieldWrapper>
        <SearchField
          variant="standard"
          placeholder="Filter by KR's name"
          readOnly={props.readOnly}
          initialValue={props.initialName}
          onSubmit={(name) => props.onChangeFilter('name', name)}
          sx={{ flexDirection: 'row' }}
        />
      </FilterFieldWrapper>

      {!props.readOnly && (
        <FilterFieldWrapper flexGrow={1}>
          <KeyResultFilterViewMode
            value={props.selectedViewMode}
            onChange={props.onChangeViewMode}
          />
        </FilterFieldWrapper>
      )}
    </Filter>
  );
}

export default KeyResultFilter;
