import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { ContributorFormatted, formatContributor } from './mappers/contributor.mapper';
import { formatAgreements, RawContributor } from './mappers/keyResultAgreements.mapper';
import { FocusArea, KeyResult, Objective } from '../utils';

interface GetKeyResultsByUserId {
  getKeyResults: Array<KeyResult & { objective: Objective; contributors: RawContributor[] }>;
}

export function useGetKeyResultsByUserId(options: QueryHookOptions<GetKeyResultsByUserId>) {
  const { enqueueSnackbar } = useSnackbar();

  const { data, ...result } = useQuery<GetKeyResultsByUserId>(getKeyResultsByUserIdQuery, {
    onError: function (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    ...options,
  });

  const mappedData = useMemo(
    function () {
      if (!data || !data.getKeyResults) {
        return [];
      }

      return data.getKeyResults.map(function (kr) {
        const contributors = kr.contributors.map(formatContributor) as ContributorFormatted[];

        return {
          ...kr,
          id: kr.id,
          objective: {
            ...kr.objective,
          },
          name: kr.name,
          status: kr.status,
          deadline: parseISO(kr.deadline.slice(0, 10)),
          lastUpdate: new Date(kr.updatedAt),
          owner: contributors.find(({ type }) => type.toLocaleLowerCase() === 'owner')!,
          contributors,
          fiscalYear: kr.fiscal_year,
          focusArea: kr.focus_area?.toLowerCase() as FocusArea,
          rankingIndex: kr.ranking_index,
          agreements: formatAgreements(kr.contributors),
          weightMultiplier: kr.weight_multiplier,
        };
      });
    },
    [data]
  );

  return { data: { getKeyResultsByUserId: mappedData }, ...result };
}

export const getKeyResultsByUserIdQuery = gql`
  query ($user_id: String!, $filter: KeyResultFilter) {
    getKeyResults(userId: $user_id, filter: $filter) {
      id
      name
      status
      deadline
      updatedAt
      fiscal_year
      focus_area
      how_to_calculate_metric
      ranking_index
      is_closed
      weight_multiplier
      objective {
        name
        description
      }
      contributors {
        user_type
        user_kr_weight
        user_kr_weight_real
        agreement
        user_id
        name
        is_active
        employee {
          preferredName
          profilePicture
        }
      }
    }
  }
`;
