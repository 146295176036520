import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ElementType } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { UserEmployee } from '../../utils/types/user.types';

export interface PageLayoutListItemProps {
  icon: ElementType;
  label: string;
  to: string;
  is_admin?: boolean;
  user?: UserEmployee;
  shouldHideName?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  reloadDocument?: boolean;
}

function PageLayoutListItem({
  icon: Icon,
  label,
  to,
  is_admin,
  user,
  shouldHideName,
  onClick,
  isDisabled = false,
  reloadDocument = false,
}: PageLayoutListItemProps) {
  const { pathname } = useLocation();

  if ((is_admin && !user?.roles.includes('admin')) || isDisabled) {
    return null;
  }

  return (
    <ListItem dense disableGutters disablePadding>
      <ListItemButton
        data-testid={label}
        component={Link}
        to={to}
        reloadDocument={reloadDocument || to === pathname}
        disableGutters={shouldHideName}
        onClick={onClick}
        sx={{
          color: ({ palette }) => (pathname === to ? palette.text.primary : palette.text.disabled),
          ...(shouldHideName && { justifyContent: 'center' }),
          '& .MuiListItemIcon-root': {
            color: ({ palette }) =>
              pathname === to ? palette.primary.main : palette.text.disabled,
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 0, mr: shouldHideName ? 0 : 1.5 }}>
          <Icon sx={{ fontSize: '20px' }} />
        </ListItemIcon>

        {!shouldHideName && (
          <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
        )}
      </ListItemButton>
    </ListItem>
  );
}

export default PageLayoutListItem;
