export function validatePasswords(values: { newPassword: string; confirmationPassword: string }) {
  const wasNewPasswordTouched = values.newPassword.length > 0;
  const wasConfirmationPasswordTouched = values.confirmationPassword.length > 0;
  const errors: { newPassword?: string; confirmationPassword?: string } = {};

  if (!wasNewPasswordTouched) {
    return errors;
  }

  if (!/(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s])/g.test(values.newPassword)) {
    errors.newPassword =
      'The password must contain at least one uppercase letter, one number and one special character';
  }

  if (values.newPassword.length < 6) {
    errors.newPassword = 'The password must be at least 6 characters long';
  }

  if (wasConfirmationPasswordTouched && values.newPassword !== values.confirmationPassword) {
    errors.confirmationPassword = 'Must be equal to the new password';
  }

  return errors;
}
