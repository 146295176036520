import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Box, Button, Tooltip } from '@mui/material';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

export interface FullscreenProps {
  children: ReactNode;
}

function Fullscreen(props: FullscreenProps) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const wrapper = useRef<HTMLElement>();

  const toggleFullscreen = useCallback(() => {
    if (isFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
      return;
    }

    if (wrapper.current) {
      wrapper.current.requestFullscreen();
      setIsFullscreen(true);
    }
  }, [isFullscreen]);

  useEffect(() => {
    function handleFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  const Icon = isFullscreen ? FullscreenExitIcon : FullscreenIcon;
  const tip = isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen';

  return (
    <Box
      position="relative"
      bgcolor="background.page"
      overflow="auto"
      ref={wrapper}
      sx={{
        '&:fullscreen': {
          padding: 2,
          '& > [type="button"]': ({ spacing }) => ({
            position: 'fixed',
            top: spacing(2),
            right: spacing(2),
          }),
        },
      }}
    >
      {document.fullscreenEnabled && (
        <Tooltip title={tip}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={toggleFullscreen}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 2,
              padding: 0,
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '20px',
            }}
          >
            <Icon />
          </Button>
        </Tooltip>
      )}

      {props.children}
    </Box>
  );
}

export default Fullscreen;
