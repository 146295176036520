import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export const API_URI = process.env.REACT_APP_API_URL;

const httpLink = new HttpLink({ uri: API_URI });

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const hasSessionExpired = graphQLErrors?.[0].message === 'Unauthorized!';
  const isAuthenticationQuery = graphQLErrors?.[0].path?.includes('getCurrentUser');

  if (hasSessionExpired && !isAuthenticationQuery) {
    window.location.reload();
  } else {
    forward(operation);
  }
});

const authLink = new ApolloLink(function (operation, forward) {
  const token = localStorage.getItem('token');

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${JSON.parse(token)}` : '',
    },
  });

  return forward(operation);
});

export const apiClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
});
