import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { alpha, createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const colors = {
  primary: {
    main: '#D52B1E',
    dark: '#B52124',
    light: '#FFE4E4',
  },
  success: {
    main: '#69BE28',
    dark: '#4F9F11',
    light: '#A9FF3D',
  },
  warning: {
    main: '#F58A00',
    dark: '#CC7300',
    light: '#FFA127',
  },
  error: {
    main: '#FF1100',
    dark: '#D52B1E',
    light: '#FF3D2E',
  },
};

export const theme = createTheme({
  palette: {
    ...colors,
    background: {
      page: '#FCF5F4',
      table: '#7E0000',
      accent: colors.primary.light,
      accentHighlight: '#FFD8D8',
    },
    border: {
      default: colors.primary.light,
      highlight: '#6F6F6F',
    },
    shadow: {
      default: alpha(colors.primary.main, 0.125),
      dark: alpha(colors.primary.dark, 0.225),
    },
  },
  components: {
    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: 'flex-end',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        outlined: ({ theme }) => ({
          borderColor: theme.palette.border.default,
          backgroundColor: theme.palette.background.paper,
        }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.primary.light}`,
          borderRadius: theme.spacing(1),
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.table,
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:not(.MuiTableRow-head):last-of-type > :before': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
          },
        }),
        head: ({ theme }) => ({
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          color: theme.palette.common.white,
          fontSize: '0.625rem',
          fontWeight: theme.typography.fontWeightBold,
          letterSpacing: '2px',
          lineHeight: '1.125rem',
          textTransform: 'uppercase',
        }),
        body: ({ theme }) => ({
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          position: 'relative',
          borderBottom: 0,
          verticalAlign: 'top',
          '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            borderBottom: `1px solid ${theme.palette.primary.light}`,
          },
          '&:first-of-type:before': {
            left: theme.spacing(3),
          },
          '&:last-of-type:before': {
            right: theme.spacing(3),
          },
        }),
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'md',
      },
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.spacing(2.5),
          background:
            'linear-gradient(180deg, rgba(255,219,218,1) 0%, rgba(255,255,255,1) 48%, rgba(255,255,255,1) 100%)',
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.dark,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3),
          justifyContent: 'left',
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'success' &&
            ownerState.variant === 'contained' && { color: theme.palette.common.white }),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'info' && { backgroundColor: theme.palette.info.light }),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.border.default,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'success' && { color: theme.palette.common.white }),
        }),
      },
    },
  },
});
