import { MenuItem, MenuProps, Select, SelectProps } from '@mui/material';

import { getFilterableYears } from '../../utils';

export interface FiscalYearFilterProps extends SelectProps<string> {}

function FiscalYearFilter(props: FiscalYearFilterProps) {
  return (
    <Select
      data-testid="fiscal-year-filter"
      size="small"
      variant="standard"
      MenuProps={{ 'data-testid': 'fiscal-year-filter-menu' } as Partial<MenuProps>}
      inputProps={{ 'data-testid': 'fiscal-year-filter-input' }}
      sx={{ minWidth: '70px' }}
      displayEmpty={Boolean(props.placeholder)}
      {...props}
    >
      {Boolean(props.placeholder) && (
        <MenuItem value="" disabled data-testid="fiscal-year-filter-placeholder">
          <em>{props.placeholder}</em>
        </MenuItem>
      )}

      {getFilterableYears().map((year) => (
        <MenuItem key={year} value={year} data-testid="fiscal-year-filter-option">
          {year}
        </MenuItem>
      ))}
    </Select>
  );
}

export default FiscalYearFilter;
